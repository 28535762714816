/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { BaseMap } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getBaseMapById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BaseMap>(
    {
      url: `/api/BaseMaps/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBaseMapByIdQueryKey = (id: number) => {
  return [`/api/BaseMaps/${id}`] as const;
};

export const getGetBaseMapByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getBaseMapById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBaseMapById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBaseMapByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBaseMapById>>> = ({
    signal,
  }) => getBaseMapById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBaseMapById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBaseMapByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBaseMapById>>
>;
export type GetBaseMapByIdQueryError = ErrorType<void>;

export const useGetBaseMapById = <
  TData = Awaited<ReturnType<typeof getBaseMapById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBaseMapById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBaseMapByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBaseMap = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BaseMap[]>(
    { url: `/api/BaseMaps`, method: 'GET', signal },
    options
  );
};

export const getGetBaseMapQueryKey = () => {
  return [`/api/BaseMaps`] as const;
};

export const getGetBaseMapQueryOptions = <
  TData = Awaited<ReturnType<typeof getBaseMap>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getBaseMap>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBaseMapQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBaseMap>>> = ({
    signal,
  }) => getBaseMap(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBaseMap>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBaseMapQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBaseMap>>
>;
export type GetBaseMapQueryError = ErrorType<void>;

export const useGetBaseMap = <
  TData = Awaited<ReturnType<typeof getBaseMap>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getBaseMap>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBaseMapQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postBaseMap = (
  baseMap: BodyType<BaseMap>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<BaseMap>(
    {
      url: `/api/BaseMaps`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: baseMap,
    },
    options
  );
};

export const getPostBaseMapMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postBaseMap>>,
    TError,
    { data: BodyType<BaseMap> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postBaseMap>>,
  TError,
  { data: BodyType<BaseMap> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postBaseMap>>,
    { data: BodyType<BaseMap> }
  > = (props) => {
    const { data } = props ?? {};

    return postBaseMap(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostBaseMapMutationResult = NonNullable<
  Awaited<ReturnType<typeof postBaseMap>>
>;
export type PostBaseMapMutationBody = BodyType<BaseMap>;
export type PostBaseMapMutationError = ErrorType<void>;

export const usePostBaseMap = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postBaseMap>>,
    TError,
    { data: BodyType<BaseMap> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postBaseMap>>,
  TError,
  { data: BodyType<BaseMap> },
  TContext
> => {
  const mutationOptions = getPostBaseMapMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putBaseMapById = (
  id: number,
  baseMap: BodyType<BaseMap>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<BaseMap>(
    {
      url: `/api/BaseMaps/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: baseMap,
    },
    options
  );
};

export const getPutBaseMapByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putBaseMapById>>,
    TError,
    { id: number; data: BodyType<BaseMap> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putBaseMapById>>,
  TError,
  { id: number; data: BodyType<BaseMap> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putBaseMapById>>,
    { id: number; data: BodyType<BaseMap> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putBaseMapById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutBaseMapByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putBaseMapById>>
>;
export type PutBaseMapByIdMutationBody = BodyType<BaseMap>;
export type PutBaseMapByIdMutationError = ErrorType<void>;

export const usePutBaseMapById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putBaseMapById>>,
    TError,
    { id: number; data: BodyType<BaseMap> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putBaseMapById>>,
  TError,
  { id: number; data: BodyType<BaseMap> },
  TContext
> => {
  const mutationOptions = getPutBaseMapByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteBaseMapById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<BaseMap>(
    {
      url: `/api/BaseMaps/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteBaseMapByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBaseMapById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteBaseMapById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteBaseMapById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteBaseMapById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteBaseMapByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteBaseMapById>>
>;

export type DeleteBaseMapByIdMutationError = ErrorType<void>;

export const useDeleteBaseMapById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBaseMapById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteBaseMapById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteBaseMapByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
