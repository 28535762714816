/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Farm, FarmSummary, FarmSummaryPage } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getFarm = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Farm[]>(
    { url: `/api/Farms`, method: 'GET', signal },
    options
  );
};

export const getGetFarmQueryKey = () => {
  return [`/api/Farms`] as const;
};

export const getGetFarmQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarm>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFarm>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFarmQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFarm>>> = ({
    signal,
  }) => getFarm(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarm>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFarm>>
>;
export type GetFarmQueryError = ErrorType<void>;

export const useGetFarm = <
  TData = Awaited<ReturnType<typeof getFarm>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getFarm>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postFarm = (
  farm: BodyType<Farm>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Farm>(
    {
      url: `/api/Farms`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: farm,
    },
    options
  );
};

export const getPostFarmMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postFarm>>,
    TError,
    { data: BodyType<Farm> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postFarm>>,
  TError,
  { data: BodyType<Farm> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postFarm>>,
    { data: BodyType<Farm> }
  > = (props) => {
    const { data } = props ?? {};

    return postFarm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFarmMutationResult = NonNullable<
  Awaited<ReturnType<typeof postFarm>>
>;
export type PostFarmMutationBody = BodyType<Farm>;
export type PostFarmMutationError = ErrorType<void>;

export const usePostFarm = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postFarm>>,
    TError,
    { data: BodyType<Farm> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postFarm>>,
  TError,
  { data: BodyType<Farm> },
  TContext
> => {
  const mutationOptions = getPostFarmMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedUserFarmSummary = (
  pageNumber: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<FarmSummaryPage[]>(
    {
      url: `/api/Farms/GetPagedUserFarmSummary/${encodeURIComponent(
        String(pageNumber)
      )}/${encodeURIComponent(String(pageSize))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedUserFarmSummaryQueryKey = (
  pageNumber: number,
  pageSize: number
) => {
  return [
    `/api/Farms/GetPagedUserFarmSummary/${pageNumber}/${pageSize}`,
  ] as const;
};

export const getGetPagedUserFarmSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedUserFarmSummary>>,
  TError = ErrorType<void>
>(
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedUserFarmSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedUserFarmSummaryQueryKey(pageNumber, pageSize);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedUserFarmSummary>>
  > = ({ signal }) =>
    getPagedUserFarmSummary(pageNumber, pageSize, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(pageNumber && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedUserFarmSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedUserFarmSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedUserFarmSummary>>
>;
export type GetPagedUserFarmSummaryQueryError = ErrorType<void>;

export const useGetPagedUserFarmSummary = <
  TData = Awaited<ReturnType<typeof getPagedUserFarmSummary>>,
  TError = ErrorType<void>
>(
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedUserFarmSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedUserFarmSummaryQueryOptions(
    pageNumber,
    pageSize,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserFarmSummary = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<FarmSummaryPage[]>(
    {
      url: `/api/Farms/GetUserFarmSummary/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUserFarmSummaryQueryKey = (id: number) => {
  return [`/api/Farms/GetUserFarmSummary/${id}`] as const;
};

export const getGetUserFarmSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFarmSummary>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFarmSummaryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserFarmSummary>>
  > = ({ signal }) => getUserFarmSummary(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFarmSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFarmSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFarmSummary>>
>;
export type GetUserFarmSummaryQueryError = ErrorType<void>;

export const useGetUserFarmSummary = <
  TData = Awaited<ReturnType<typeof getUserFarmSummary>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFarmSummaryQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserFarmSummaryPlantCounts = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<FarmSummaryPage>(
    {
      url: `/api/Farms/GetUserFarmSummaryPlantCounts/${encodeURIComponent(
        String(id)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUserFarmSummaryPlantCountsQueryKey = (id: number) => {
  return [`/api/Farms/GetUserFarmSummaryPlantCounts/${id}`] as const;
};

export const getGetUserFarmSummaryPlantCountsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserFarmSummaryPlantCountsQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>
  > = ({ signal }) => getUserFarmSummaryPlantCounts(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFarmSummaryPlantCountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>
>;
export type GetUserFarmSummaryPlantCountsQueryError = ErrorType<void>;

export const useGetUserFarmSummaryPlantCounts = <
  TData = Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmSummaryPlantCounts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFarmSummaryPlantCountsQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const farmSearch = (
  farmName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<FarmSummary[]>(
    {
      url: `/api/Farms/Search/${encodeURIComponent(String(farmName))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getFarmSearchQueryKey = (farmName: string) => {
  return [`/api/Farms/Search/${farmName}`] as const;
};

export const getFarmSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof farmSearch>>,
  TError = ErrorType<void>
>(
  farmName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof farmSearch>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFarmSearchQueryKey(farmName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof farmSearch>>> = ({
    signal,
  }) => farmSearch(farmName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!farmName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof farmSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FarmSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof farmSearch>>
>;
export type FarmSearchQueryError = ErrorType<void>;

export const useFarmSearch = <
  TData = Awaited<ReturnType<typeof farmSearch>>,
  TError = ErrorType<void>
>(
  farmName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof farmSearch>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFarmSearchQueryOptions(farmName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFarmById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Farm>(
    {
      url: `/api/Farms/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetFarmByIdQueryKey = (id: number) => {
  return [`/api/Farms/${id}`] as const;
};

export const getGetFarmByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFarmById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFarmByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFarmById>>> = ({
    signal,
  }) => getFarmById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarmById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFarmById>>
>;
export type GetFarmByIdQueryError = ErrorType<void>;

export const useGetFarmById = <
  TData = Awaited<ReturnType<typeof getFarmById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFarmById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putFarm = (
  id: number,
  farm: BodyType<Farm>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Farm>(
    {
      url: `/api/Farms/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: farm,
    },
    options
  );
};

export const getPutFarmMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putFarm>>,
    TError,
    { id: number; data: BodyType<Farm> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putFarm>>,
  TError,
  { id: number; data: BodyType<Farm> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putFarm>>,
    { id: number; data: BodyType<Farm> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putFarm(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutFarmMutationResult = NonNullable<
  Awaited<ReturnType<typeof putFarm>>
>;
export type PutFarmMutationBody = BodyType<Farm>;
export type PutFarmMutationError = ErrorType<void>;

export const usePutFarm = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putFarm>>,
    TError,
    { id: number; data: BodyType<Farm> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putFarm>>,
  TError,
  { id: number; data: BodyType<Farm> },
  TContext
> => {
  const mutationOptions = getPutFarmMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteFarm = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Farm>(
    { url: `/api/Farms/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteFarmMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFarm>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFarm>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFarm>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteFarm(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFarmMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFarm>>
>;

export type DeleteFarmMutationError = ErrorType<void>;

export const useDeleteFarm = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFarm>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteFarm>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteFarmMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUserFarmsByUserId = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Farm[]>(
    {
      url: `/api/Farms/GetUserFarms/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUserFarmsByUserIdQueryKey = (userId: string) => {
  return [`/api/Farms/GetUserFarms/${userId}`] as const;
};

export const getGetUserFarmsByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFarmsByUserId>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmsByUserId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserFarmsByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserFarmsByUserId>>
  > = ({ signal }) => getUserFarmsByUserId(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFarmsByUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFarmsByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFarmsByUserId>>
>;
export type GetUserFarmsByUserIdQueryError = ErrorType<void>;

export const useGetUserFarmsByUserId = <
  TData = Awaited<ReturnType<typeof getUserFarmsByUserId>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserFarmsByUserId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFarmsByUserIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUserFarms = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Farm[]>(
    { url: `/api/Farms/GetUserFarms`, method: 'GET', signal },
    options
  );
};

export const getGetUserFarmsQueryKey = () => {
  return [`/api/Farms/GetUserFarms`] as const;
};

export const getGetUserFarmsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserFarms>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserFarms>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFarmsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserFarms>>> = ({
    signal,
  }) => getUserFarms(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserFarms>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserFarmsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserFarms>>
>;
export type GetUserFarmsQueryError = ErrorType<void>;

export const useGetUserFarms = <
  TData = Awaited<ReturnType<typeof getUserFarms>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserFarms>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserFarmsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomerFarms = (
  customerId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Farm[]>(
    {
      url: `/api/Farms/GetCustomerFarms/${encodeURIComponent(
        String(customerId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerFarmsQueryKey = (customerId: number) => {
  return [`/api/Farms/GetCustomerFarms/${customerId}`] as const;
};

export const getGetCustomerFarmsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerFarms>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerFarms>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomerFarmsQueryKey(customerId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerFarms>>
  > = ({ signal }) => getCustomerFarms(customerId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerFarms>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerFarmsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerFarms>>
>;
export type GetCustomerFarmsQueryError = ErrorType<void>;

export const useGetCustomerFarms = <
  TData = Awaited<ReturnType<typeof getCustomerFarms>>,
  TError = ErrorType<void>
>(
  customerId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerFarms>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerFarmsQueryOptions(customerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
