/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Customer } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCustomer = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer[]>(
    { url: `/api/Customers`, method: 'GET', signal },
    options
  );
};

export const getGetCustomerQueryKey = () => {
  return [`/api/Customers`] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({
    signal,
  }) => getCustomer(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomer>>
>;
export type GetCustomerQueryError = ErrorType<void>;

export const useGetCustomer = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCustomer = (
  customer: BodyType<Customer>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: customer,
    },
    options
  );
};

export const getPostCustomerMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomer>>,
    TError,
    { data: BodyType<Customer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomer>>,
  TError,
  { data: BodyType<Customer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomer>>,
    { data: BodyType<Customer> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomer>>
>;
export type PostCustomerMutationBody = BodyType<Customer>;
export type PostCustomerMutationError = ErrorType<void>;

export const usePostCustomer = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomer>>,
    TError,
    { data: BodyType<Customer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomer>>,
  TError,
  { data: BodyType<Customer> },
  TContext
> => {
  const mutationOptions = getPostCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerByIdQueryKey = (id: number) => {
  return [`/api/Customers/${id}`] as const;
};

export const getGetCustomerByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerById>>> = ({
    signal,
  }) => getCustomerById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerById>>
>;
export type GetCustomerByIdQueryError = ErrorType<void>;

export const useGetCustomerById = <
  TData = Awaited<ReturnType<typeof getCustomerById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCustomer = (
  id: number,
  customer: BodyType<Customer>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: customer,
    },
    options
  );
};

export const getPutCustomerMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomer>>,
    TError,
    { id: number; data: BodyType<Customer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCustomer>>,
  TError,
  { id: number; data: BodyType<Customer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCustomer>>,
    { id: number; data: BodyType<Customer> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCustomer(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCustomer>>
>;
export type PutCustomerMutationBody = BodyType<Customer>;
export type PutCustomerMutationError = ErrorType<void>;

export const usePutCustomer = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomer>>,
    TError,
    { id: number; data: BodyType<Customer> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCustomer>>,
  TError,
  { id: number; data: BodyType<Customer> },
  TContext
> => {
  const mutationOptions = getPutCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCustomer = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCustomerMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomer>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCustomer>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCustomer>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCustomer(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomer>>
>;

export type DeleteCustomerMutationError = ErrorType<void>;

export const useDeleteCustomer = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomer>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCustomer>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerByCollectionId = (
  collectionId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers/Collections/${encodeURIComponent(
        String(collectionId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerByCollectionIdQueryKey = (collectionId: number) => {
  return [`/api/Customers/Collections/${collectionId}`] as const;
};

export const getGetCustomerByCollectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerByCollectionIdQueryKey(collectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerByCollectionId>>
  > = ({ signal }) =>
    getCustomerByCollectionId(collectionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!collectionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerByCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerByCollectionId>>
>;
export type GetCustomerByCollectionIdQueryError = ErrorType<void>;

export const useGetCustomerByCollectionId = <
  TData = Awaited<ReturnType<typeof getCustomerByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerByCollectionIdQueryOptions(
    collectionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomersByCustomerAnalyticId = (
  customerAnalyticId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer[]>(
    {
      url: `/api/Customers/CustomerAnalytics/${encodeURIComponent(
        String(customerAnalyticId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomersByCustomerAnalyticIdQueryKey = (
  customerAnalyticId: number
) => {
  return [`/api/Customers/CustomerAnalytics/${customerAnalyticId}`] as const;
};

export const getGetCustomersByCustomerAnalyticIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>,
  TError = ErrorType<void>
>(
  customerAnalyticId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomersByCustomerAnalyticIdQueryKey(customerAnalyticId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>
  > = ({ signal }) =>
    getCustomersByCustomerAnalyticId(
      customerAnalyticId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!customerAnalyticId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomersByCustomerAnalyticIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>
>;
export type GetCustomersByCustomerAnalyticIdQueryError = ErrorType<void>;

export const useGetCustomersByCustomerAnalyticId = <
  TData = Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>,
  TError = ErrorType<void>
>(
  customerAnalyticId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomersByCustomerAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomersByCustomerAnalyticIdQueryOptions(
    customerAnalyticId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomersForUser = (
  userId: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer[]>(
    {
      url: `/api/Customers/Users/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomersForUserQueryKey = (userId: string) => {
  return [`/api/Customers/Users/${userId}`] as const;
};

export const getGetCustomersForUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomersForUser>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomersForUser>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomersForUserQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomersForUser>>
  > = ({ signal }) => getCustomersForUser(userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomersForUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomersForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomersForUser>>
>;
export type GetCustomersForUserQueryError = ErrorType<void>;

export const useGetCustomersForUser = <
  TData = Awaited<ReturnType<typeof getCustomersForUser>>,
  TError = ErrorType<void>
>(
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomersForUser>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomersForUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomerByCollectionUuid = (
  collectionUuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer>(
    {
      url: `/api/Customers/Collections/Uuid/${encodeURIComponent(
        String(collectionUuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCustomerByCollectionUuidQueryKey = (
  collectionUuid: string
) => {
  return [`/api/Customers/Collections/Uuid/${collectionUuid}`] as const;
};

export const getGetCustomerByCollectionUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerByCollectionUuid>>,
  TError = ErrorType<void>
>(
  collectionUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerByCollectionUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerByCollectionUuidQueryKey(collectionUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerByCollectionUuid>>
  > = ({ signal }) =>
    getCustomerByCollectionUuid(collectionUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!collectionUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerByCollectionUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerByCollectionUuid>>
>;
export type GetCustomerByCollectionUuidQueryError = ErrorType<void>;

export const useGetCustomerByCollectionUuid = <
  TData = Awaited<ReturnType<typeof getCustomerByCollectionUuid>>,
  TError = ErrorType<void>
>(
  collectionUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCustomerByCollectionUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerByCollectionUuidQueryOptions(
    collectionUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomerByCollectionList = (
  getCustomerByCollectionListBody: BodyType<string[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Customer[]>(
    {
      url: `/api/Customers/Collections/Uuid`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getCustomerByCollectionListBody,
    },
    options
  );
};

export const getGetCustomerByCollectionListMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionList>>,
    TError,
    { data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCustomerByCollectionList>>,
  TError,
  { data: BodyType<string[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCustomerByCollectionList>>,
    { data: BodyType<string[]> }
  > = (props) => {
    const { data } = props ?? {};

    return getCustomerByCollectionList(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCustomerByCollectionListMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerByCollectionList>>
>;
export type GetCustomerByCollectionListMutationBody = BodyType<string[]>;
export type GetCustomerByCollectionListMutationError = ErrorType<void>;

export const useGetCustomerByCollectionList = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionList>>,
    TError,
    { data: BodyType<string[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getCustomerByCollectionList>>,
  TError,
  { data: BodyType<string[]> },
  TContext
> => {
  const mutationOptions =
    getGetCustomerByCollectionListMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerByCollectionIdList = (
  getCustomerByCollectionIdListBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Customer[]>(
    {
      url: `/api/Customers/Collections`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getCustomerByCollectionIdListBody,
    },
    options
  );
};

export const getGetCustomerByCollectionIdListMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionIdList>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getCustomerByCollectionIdList>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCustomerByCollectionIdList>>,
    { data: BodyType<number[]> }
  > = (props) => {
    const { data } = props ?? {};

    return getCustomerByCollectionIdList(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetCustomerByCollectionIdListMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerByCollectionIdList>>
>;
export type GetCustomerByCollectionIdListMutationBody = BodyType<number[]>;
export type GetCustomerByCollectionIdListMutationError = ErrorType<void>;

export const useGetCustomerByCollectionIdList = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCustomerByCollectionIdList>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getCustomerByCollectionIdList>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions =
    getGetCustomerByCollectionIdListMutationOptions(options);

  return useMutation(mutationOptions);
};
export const customerSearch = (
  customerName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Customer[]>(
    {
      url: `/api/Customers/Names/${encodeURIComponent(String(customerName))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getCustomerSearchQueryKey = (customerName: string) => {
  return [`/api/Customers/Names/${customerName}`] as const;
};

export const getCustomerSearchQueryOptions = <
  TData = Awaited<ReturnType<typeof customerSearch>>,
  TError = ErrorType<void>
>(
  customerName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof customerSearch>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCustomerSearchQueryKey(customerName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof customerSearch>>> = ({
    signal,
  }) => customerSearch(customerName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof customerSearch>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CustomerSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof customerSearch>>
>;
export type CustomerSearchQueryError = ErrorType<void>;

export const useCustomerSearch = <
  TData = Awaited<ReturnType<typeof customerSearch>>,
  TError = ErrorType<void>
>(
  customerName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof customerSearch>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCustomerSearchQueryOptions(customerName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
