import {
  faCircleCheck,
  faCircleNotch,
  faPlus,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as faSolidCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { APIModels } from '@agerpoint/api';
import { Button, Input } from '@agerpoint/component';
import { Datatable, dataTableAgerStyle } from '@agerpoint/feature';
import { LdFlags } from '@agerpoint/types';
import {
  hasPermission,
  useGlobalStore,
  useIsViteApp,
  useItemSelection,
  useLookupTable,
  usePageTitle,
  useQueryState,
} from '@agerpoint/utilities';

import { useAdminJobTypesQueries } from './admin-job-types-queries';

export const AdminJobTypesList = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { permissions } = useGlobalStore();
  usePageTitle(() => 'Platform - Job Types', []);

  const [nameFilter, setNameFilter] = useQueryState({
    paramName: 'name',
    initialValue: '',
    toUrlParam: (value) => value.trim(),
    fromUrlParam: (value) => value.trim(),
  });

  const canManageAnalyticRequests = useMemo(
    () => hasPermission(LdFlags.AnalyticRequestManagement, permissions),
    [permissions]
  );

  const [archivedFilter, setArchivedFilter] = useQueryState<boolean>({
    paramName: 'archived',
    initialValue: false,
    fromUrlParam: (a) => {
      if (a === 'yes') {
        return true;
      }
      return false;
    },
    toUrlParam: (a) => {
      return a ? 'yes' : '';
    },
  });

  const { jobTypesQuery, mosaicEnginesQuery, jobTypeBulkSetArchivedMutation } =
    useAdminJobTypesQueries(archivedFilter);

  const jobTypesSelection = useItemSelection<number, APIModels.JobType>({
    items: jobTypesQuery.data ?? [],
    idField: 'id',
    dependencies: [jobTypesQuery.data?.length, archivedFilter],
  });

  const mosaicEnginesLookupTable = useLookupTable(
    mosaicEnginesQuery.data,
    'id'
  );

  const bulkSetArchived = useCallback(() => {
    if (
      !jobTypesSelection.hasSelectedItems ||
      jobTypeBulkSetArchivedMutation.isPending
    ) {
      return;
    }

    const confirmed = window.confirm(
      `Are you sure you want to ${
        archivedFilter ? 'restore' : 'archive'
      } selected job types?`
    );

    if (!confirmed) {
      return;
    }

    const jobTypes: APIModels.JobType[] = jobTypesSelection
      .getSelectionArray()
      .map((jobType) => ({
        ...jobType,
        archived: !archivedFilter,
      }));

    jobTypeBulkSetArchivedMutation.mutate(
      {
        jobTypes: [...jobTypes],
      },
      {
        onSuccess: () => {
          jobTypesSelection.clearSelection();
        },
      }
    );
  }, [
    jobTypesSelection.selectionSize,
    archivedFilter,
    queryClient,
    jobTypeBulkSetArchivedMutation,
  ]);

  const filteredJobTypes = useMemo(() => {
    if (!jobTypesQuery.data) return [];
    let result = [...jobTypesQuery.data];

    if (nameFilter.trim() !== '') {
      result = result.filter((jobType) => {
        return jobType.name
          ?.toLowerCase()
          .includes(nameFilter.toLowerCase().trim());
      });
    }

    return result;
  }, [jobTypesQuery.data, nameFilter]);

  const hasFiltersApplied = useMemo(
    () => !!(nameFilter?.length ?? 0),
    [nameFilter]
  );

  const isViteApp = useIsViteApp();

  const [search] = useSearchParams();

  const clearFilters = useCallback(() => {
    setNameFilter('');

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('name');

    navigate(
      {
        pathname: isViteApp
          ? '/app/admin/platform/job-types'
          : '/admin/job-types',
        search: searchParams.toString(),
      },
      {
        replace: true,
      }
    );
  }, [search]);

  return (
    <div className="w-full h-full flex flex-col pt-4">
      <div className="px-4 py-2 flex flex-row gap-2 justify-between items-center">
        <h1 className="text-3xl font-bold">Job Types</h1>
        {canManageAnalyticRequests && (
          <div className="flex flex-row gap-2 items-center">
            {jobTypesSelection.hasSelectedItems &&
              (archivedFilter ? (
                <Button.Secondary
                  id="job-types-list-restore-selected"
                  label="Restore"
                  onClick={bulkSetArchived}
                  loading={jobTypeBulkSetArchivedMutation.isPending}
                />
              ) : (
                <Button.Danger
                  id="job-types-list-archive-selected"
                  label="Archive"
                  onClick={bulkSetArchived}
                  loading={jobTypeBulkSetArchivedMutation.isPending}
                />
              ))}

            {jobTypesSelection.hasSelectedItems && (
              <Button.Secondary
                id="clear-selection-button"
                onClick={() => {
                  jobTypesSelection.clearSelection();
                }}
                label={`Clear Selection (${jobTypesSelection.selectionSize})`}
                icon={faXmark}
              />
            )}

            <Button.Primary
              id="job-types-new-button"
              label="New Job Type"
              icon={faPlus}
              onClick={() => {
                navigate('new', {
                  state: {
                    params: window.location.search,
                  },
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2 px-4 flex-wrap">
        <div className="max-w-sm w-full">
          <Input.Text.Single
            id="analytic-name-filter"
            value={nameFilter}
            setValue={setNameFilter}
            placeholder="Search by Name"
            placeholderIcon={Input.placeholderIcons.search}
          />
        </div>
        <Input.Select.Inline
          id="capture-status-filter"
          options={[false, true]}
          optionBuilder={(o) => (o ? 'Archived' : 'Active')}
          value={archivedFilter}
          setValue={setArchivedFilter}
        />
        <Button.ClearFilter
          onClick={clearFilters}
          visible={hasFiltersApplied}
        />
      </div>
      <div className="w-full h-full p-4">
        <Datatable
          id="admin-job-types-datatable"
          data={filteredJobTypes || []}
          loading={jobTypesQuery.isLoading}
          cellOnClick={() => {
            return (row) => {
              navigate(`${row.id}/details`, {
                state: { params: window.location.search },
              });
            };
          }}
          noResults={
            canManageAnalyticRequests
              ? {
                  title: 'No job types yet',
                  message: 'Create job type to get started',
                  action: () => {
                    navigate('new', {
                      state: {
                        params: window.location.search,
                      },
                    });
                  },
                  actionIcon: <FontAwesomeIcon icon={faPlus} />,
                  actionLabel: 'New Job Type',
                }
              : {
                  title: 'No job types yet',
                  message: 'Contact an administrator to create job types',
                }
          }
          error={
            jobTypesQuery.isError
              ? {
                  title: 'There was a problem loading job types',
                  message: 'Try refreshing the page',
                  action: () => {
                    jobTypesQuery.refetch();
                  },
                }
              : undefined
          }
          columns={[
            { label: 'ID', value: (row) => row.id, flex: 0.25, name: 'id' },
            { label: 'Name', value: (row) => row.name, name: 'name' },
            {
              label: 'Description',
              value: (row) => row.description,
              name: 'desc',
            },
            {
              label: 'Category',
              value: (row) => row.categoryName?.split(/(?=[A-Z])/).join(' '),
              name: 'category',
            },
            {
              label: 'Req High Res',
              value: (row) => (row.requiresHighRes ? 'Yes' : 'No'),
              flex: 0.5,
              name: 'requires-high-res',
            },
            {
              label: 'Mosaic Engine',
              value: (row) => {
                if (!row.mosaicEngineId) return '';
                if (mosaicEnginesQuery.isLoading)
                  return <FontAwesomeIcon icon={faCircleNotch} spin />;

                const mosaicEng =
                  mosaicEnginesLookupTable?.[row.mosaicEngineId];
                return mosaicEng?.displayName ?? mosaicEng?.name ?? '';
              },
              flex: 0.5,
              name: 'mosaic-engine',
            },

            {
              label: (
                <div className="p-1">
                  {jobTypesSelection.isEverythingSelected ? (
                    <Button.Small
                      id="captures-list-deselect-all"
                      label="Deselect All"
                      onClick={() => {
                        jobTypesSelection.toggleSelectionEverything();
                      }}
                      icon={faSolidCircleCheck}
                      iconColor="text-accent"
                    />
                  ) : (
                    <Button.Small
                      id="captures-list-select-all"
                      label="Select All"
                      onClick={() => {
                        jobTypesSelection.toggleSelectionEverything();
                      }}
                      icon={faCircleCheck}
                    />
                  )}
                </div>
              ),
              value: function Select(row, index) {
                const isSelected = row.id
                  ? jobTypesSelection.isSelected(row.id)
                  : false;

                return (
                  <div className="p-1">
                    <Button.Icon
                      id={`captures-list-select-one-${index}`}
                      icon={isSelected ? faSolidCircleCheck : faCircleCheck}
                      onClick={(e) => {
                        if (!row.id) {
                          return;
                        }
                        if (e.shiftKey) {
                          jobTypesSelection.addBulkSelectionUntilItem(
                            row.id,
                            row
                          );
                        } else {
                          jobTypesSelection.toggleSelection(row.id, row);
                        }
                      }}
                      iconColor={isSelected ? 'text-accent' : undefined}
                    />
                  </div>
                );
              },
              style: {
                columnWrapperStyle: 'flex justify-center',
                headerStyle: 'overflow-visible',
              },
              flex: 0.5,
              visible: canManageAnalyticRequests,
            },
          ]}
          rowHeight={50}
          style={{ ...dataTableAgerStyle, tableMinWidth: 800 }}
        />
      </div>
    </div>
  );
};
