/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { UploadFile } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUploadFileById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<UploadFile>(
    {
      url: `/api/UploadFile/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUploadFileByIdQueryKey = (id: number) => {
  return [`/api/UploadFile/${id}`] as const;
};

export const getGetUploadFileByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadFileById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUploadFileById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUploadFileByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUploadFileById>>
  > = ({ signal }) => getUploadFileById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadFileById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUploadFileByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadFileById>>
>;
export type GetUploadFileByIdQueryError = ErrorType<void>;

export const useGetUploadFileById = <
  TData = Awaited<ReturnType<typeof getUploadFileById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUploadFileById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadFileByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putUploadFileById = (
  id: number,
  uploadFile: BodyType<UploadFile>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UploadFile>(
    {
      url: `/api/UploadFile/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: uploadFile,
    },
    options
  );
};

export const getPutUploadFileByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUploadFileById>>,
    TError,
    { id: number; data: BodyType<UploadFile> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUploadFileById>>,
  TError,
  { id: number; data: BodyType<UploadFile> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUploadFileById>>,
    { id: number; data: BodyType<UploadFile> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putUploadFileById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUploadFileByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUploadFileById>>
>;
export type PutUploadFileByIdMutationBody = BodyType<UploadFile>;
export type PutUploadFileByIdMutationError = ErrorType<void>;

export const usePutUploadFileById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUploadFileById>>,
    TError,
    { id: number; data: BodyType<UploadFile> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUploadFileById>>,
  TError,
  { id: number; data: BodyType<UploadFile> },
  TContext
> => {
  const mutationOptions = getPutUploadFileByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteUploadFile = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UploadFile>(
    {
      url: `/api/UploadFile/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteUploadFileMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUploadFile>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUploadFile>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUploadFile>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUploadFile(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUploadFile>>
>;

export type DeleteUploadFileMutationError = ErrorType<void>;

export const useDeleteUploadFile = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUploadFile>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUploadFile>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getUploadFilesByUploadId = (
  uploadId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<UploadFile[]>(
    {
      url: `/api/UploadFile/Upload/${encodeURIComponent(String(uploadId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetUploadFilesByUploadIdQueryKey = (uploadId: number) => {
  return [`/api/UploadFile/Upload/${uploadId}`] as const;
};

export const getGetUploadFilesByUploadIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadFilesByUploadId>>,
  TError = ErrorType<void>
>(
  uploadId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUploadFilesByUploadId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUploadFilesByUploadIdQueryKey(uploadId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUploadFilesByUploadId>>
  > = ({ signal }) =>
    getUploadFilesByUploadId(uploadId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uploadId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadFilesByUploadId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUploadFilesByUploadIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadFilesByUploadId>>
>;
export type GetUploadFilesByUploadIdQueryError = ErrorType<void>;

export const useGetUploadFilesByUploadId = <
  TData = Awaited<ReturnType<typeof getUploadFilesByUploadId>>,
  TError = ErrorType<void>
>(
  uploadId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUploadFilesByUploadId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadFilesByUploadIdQueryOptions(
    uploadId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postUploadFile = (
  uploadFile: BodyType<UploadFile>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<UploadFile>(
    {
      url: `/api/UploadFile`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: uploadFile,
    },
    options
  );
};

export const getPostUploadFileMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUploadFile>>,
    TError,
    { data: BodyType<UploadFile> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUploadFile>>,
  TError,
  { data: BodyType<UploadFile> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUploadFile>>,
    { data: BodyType<UploadFile> }
  > = (props) => {
    const { data } = props ?? {};

    return postUploadFile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUploadFile>>
>;
export type PostUploadFileMutationBody = BodyType<UploadFile>;
export type PostUploadFileMutationError = ErrorType<void>;

export const usePostUploadFile = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUploadFile>>,
    TError,
    { data: BodyType<UploadFile> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postUploadFile>>,
  TError,
  { data: BodyType<UploadFile> },
  TContext
> => {
  const mutationOptions = getPostUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};
