/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  Capture,
  CaptureCloneRequest,
  CaptureCounts,
  CaptureCustomer,
  CaptureExport,
  CaptureFilter,
  CaptureLocation,
  ParametersString,
} from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptures = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Capture[]>(
    { url: `/api/Capture`, method: 'GET', signal },
    options
  );
};

export const getGetCapturesQueryKey = () => {
  return [`/api/Capture`] as const;
};

export const getGetCapturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptures>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptures>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCapturesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaptures>>> = ({
    signal,
  }) => getCaptures(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCapturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptures>>
>;
export type GetCapturesQueryError = ErrorType<void>;

export const useGetCaptures = <
  TData = Awaited<ReturnType<typeof getCaptures>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptures>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCapturesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCapture = (
  capture: BodyType<Capture>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture>(
    {
      url: `/api/Capture`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: capture,
    },
    options
  );
};

export const getPostCaptureMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCapture>>,
    TError,
    { data: BodyType<Capture> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCapture>>,
  TError,
  { data: BodyType<Capture> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCapture>>,
    { data: BodyType<Capture> }
  > = (props) => {
    const { data } = props ?? {};

    return postCapture(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCapture>>
>;
export type PostCaptureMutationBody = BodyType<Capture>;
export type PostCaptureMutationError = ErrorType<void>;

export const usePostCapture = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCapture>>,
    TError,
    { data: BodyType<Capture> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCapture>>,
  TError,
  { data: BodyType<Capture> },
  TContext
> => {
  const mutationOptions = getPostCaptureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bulkDeleteCapture = (
  bulkDeleteCaptureBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: bulkDeleteCaptureBody,
    },
    options
  );
};

export const getBulkDeleteCaptureMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDeleteCapture>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkDeleteCapture>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkDeleteCapture>>,
    { data: BodyType<number[]> }
  > = (props) => {
    const { data } = props ?? {};

    return bulkDeleteCapture(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkDeleteCaptureMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkDeleteCapture>>
>;
export type BulkDeleteCaptureMutationBody = BodyType<number[]>;
export type BulkDeleteCaptureMutationError = ErrorType<void>;

export const useBulkDeleteCapture = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDeleteCapture>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof bulkDeleteCapture>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getBulkDeleteCaptureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedCaptures = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Capture[]>(
    {
      url: `/api/Capture/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedCapturesQueryKey = (skip: number, take: number) => {
  return [`/api/Capture/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedCapturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedCaptures>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedCapturesQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedCaptures>>
  > = ({ signal }) => getPagedCaptures(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedCaptures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedCapturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedCaptures>>
>;
export type GetPagedCapturesQueryError = ErrorType<void>;

export const useGetPagedCaptures = <
  TData = Awaited<ReturnType<typeof getPagedCaptures>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedCaptures>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedCapturesQueryOptions(skip, take, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPageCaptures = (
  skip: number,
  take: number,
  captureFilter: BodyType<CaptureFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture[]>(
    {
      url: `/api/Capture/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureFilter,
    },
    options
  );
};

export const getGetFilteredPageCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPageCaptures>>,
    TError,
    { skip: number; take: number; data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPageCaptures>>,
  TError,
  { skip: number; take: number; data: BodyType<CaptureFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPageCaptures>>,
    { skip: number; take: number; data: BodyType<CaptureFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPageCaptures(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPageCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPageCaptures>>
>;
export type GetFilteredPageCapturesMutationBody = BodyType<CaptureFilter>;
export type GetFilteredPageCapturesMutationError = ErrorType<void>;

export const useGetFilteredPageCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPageCaptures>>,
    TError,
    { skip: number; take: number; data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPageCaptures>>,
  TError,
  { skip: number; take: number; data: BodyType<CaptureFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredPageCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilteredCaptureLocations = (
  captureFilter: BodyType<CaptureFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureLocation[]>(
    {
      url: `/api/Capture/Location`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureFilter,
    },
    options
  );
};

export const getGetFilteredCaptureLocationsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureLocations>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredCaptureLocations>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredCaptureLocations>>,
    { data: BodyType<CaptureFilter> }
  > = (props) => {
    const { data } = props ?? {};

    return getFilteredCaptureLocations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredCaptureLocationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredCaptureLocations>>
>;
export type GetFilteredCaptureLocationsMutationBody = BodyType<CaptureFilter>;
export type GetFilteredCaptureLocationsMutationError = ErrorType<void>;

export const useGetFilteredCaptureLocations = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureLocations>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredCaptureLocations>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredCaptureLocationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilteredCaptureIds = (
  captureFilter: BodyType<CaptureFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureCustomer[]>(
    {
      url: `/api/Capture/Ids`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureFilter,
    },
    options
  );
};

export const getGetFilteredCaptureIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureIds>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredCaptureIds>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredCaptureIds>>,
    { data: BodyType<CaptureFilter> }
  > = (props) => {
    const { data } = props ?? {};

    return getFilteredCaptureIds(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredCaptureIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredCaptureIds>>
>;
export type GetFilteredCaptureIdsMutationBody = BodyType<CaptureFilter>;
export type GetFilteredCaptureIdsMutationError = ErrorType<void>;

export const useGetFilteredCaptureIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureIds>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredCaptureIds>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredCaptureIdsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilteredCaptureCounts = (
  captureFilter: BodyType<CaptureFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureCounts>(
    {
      url: `/api/Capture/Counts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureFilter,
    },
    options
  );
};

export const getGetFilteredCaptureCountsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureCounts>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredCaptureCounts>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredCaptureCounts>>,
    { data: BodyType<CaptureFilter> }
  > = (props) => {
    const { data } = props ?? {};

    return getFilteredCaptureCounts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredCaptureCountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredCaptureCounts>>
>;
export type GetFilteredCaptureCountsMutationBody = BodyType<CaptureFilter>;
export type GetFilteredCaptureCountsMutationError = ErrorType<void>;

export const useGetFilteredCaptureCounts = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredCaptureCounts>>,
    TError,
    { data: BodyType<CaptureFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredCaptureCounts>>,
  TError,
  { data: BodyType<CaptureFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredCaptureCountsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Capture>(
    {
      url: `/api/Capture/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureByIdQueryKey = (id: number) => {
  return [`/api/Capture/${id}`] as const;
};

export const getGetCaptureByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCaptureById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaptureById>>> = ({
    signal,
  }) => getCaptureById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureById>>
>;
export type GetCaptureByIdQueryError = ErrorType<void>;

export const useGetCaptureById = <
  TData = Awaited<ReturnType<typeof getCaptureById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCaptureById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureById = (
  id: number,
  capture: BodyType<Capture>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture>(
    {
      url: `/api/Capture/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: capture,
    },
    options
  );
};

export const getPutCaptureByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureById>>,
    TError,
    { id: number; data: BodyType<Capture> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureById>>,
  TError,
  { id: number; data: BodyType<Capture> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureById>>,
    { id: number; data: BodyType<Capture> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureById>>
>;
export type PutCaptureByIdMutationBody = BodyType<Capture>;
export type PutCaptureByIdMutationError = ErrorType<void>;

export const usePutCaptureById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureById>>,
    TError,
    { id: number; data: BodyType<Capture> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureById>>,
  TError,
  { id: number; data: BodyType<Capture> },
  TContext
> => {
  const mutationOptions = getPutCaptureByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureByUuid = (
  uuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Capture[]>(
    {
      url: `/api/Capture/Uuid/${encodeURIComponent(String(uuid))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureByUuidQueryKey = (uuid: string) => {
  return [`/api/Capture/Uuid/${uuid}`] as const;
};

export const getGetCaptureByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureByUuidQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureByUuid>>
  > = ({ signal }) => getCaptureByUuid(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureByUuid>>
>;
export type GetCaptureByUuidQueryError = ErrorType<void>;

export const useGetCaptureByUuid = <
  TData = Awaited<ReturnType<typeof getCaptureByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureByUuidQueryOptions(uuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSharedCaptureByUuid = (
  uuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Capture[]>(
    {
      url: `/api/Capture/Uuid/${encodeURIComponent(String(uuid))}/shared`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetSharedCaptureByUuidQueryKey = (uuid: string) => {
  return [`/api/Capture/Uuid/${uuid}/shared`] as const;
};

export const getGetSharedCaptureByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getSharedCaptureByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSharedCaptureByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSharedCaptureByUuidQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSharedCaptureByUuid>>
  > = ({ signal }) => getSharedCaptureByUuid(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSharedCaptureByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSharedCaptureByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSharedCaptureByUuid>>
>;
export type GetSharedCaptureByUuidQueryError = ErrorType<void>;

export const useGetSharedCaptureByUuid = <
  TData = Awaited<ReturnType<typeof getSharedCaptureByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSharedCaptureByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSharedCaptureByUuidQueryOptions(uuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const captureUploaded = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture>(
    {
      url: `/api/Capture/${encodeURIComponent(String(captureId))}/Uploaded`,
      method: 'POST',
    },
    options
  );
};

export const getCaptureUploadedMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof captureUploaded>>,
    TError,
    { captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof captureUploaded>>,
  TError,
  { captureId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof captureUploaded>>,
    { captureId: number }
  > = (props) => {
    const { captureId } = props ?? {};

    return captureUploaded(captureId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaptureUploadedMutationResult = NonNullable<
  Awaited<ReturnType<typeof captureUploaded>>
>;

export type CaptureUploadedMutationError = ErrorType<void>;

export const useCaptureUploaded = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof captureUploaded>>,
    TError,
    { captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof captureUploaded>>,
  TError,
  { captureId: number },
  TContext
> => {
  const mutationOptions = getCaptureUploadedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCaptures = (
  capture: BodyType<Capture[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture[]>(
    {
      url: `/api/Capture/Bulk`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: capture,
    },
    options
  );
};

export const getPostCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptures>>,
    TError,
    { data: BodyType<Capture[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptures>>,
  TError,
  { data: BodyType<Capture[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptures>>,
    { data: BodyType<Capture[]> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptures(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptures>>
>;
export type PostCapturesMutationBody = BodyType<Capture[]>;
export type PostCapturesMutationError = ErrorType<void>;

export const usePostCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptures>>,
    TError,
    { data: BodyType<Capture[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptures>>,
  TError,
  { data: BodyType<Capture[]> },
  TContext
> => {
  const mutationOptions = getPostCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bulkAssignCaptures = (
  customerId: number,
  bulkAssignCapturesBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/Customer/${encodeURIComponent(
        String(customerId)
      )}/Bulk`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkAssignCapturesBody,
    },
    options
  );
};

export const getBulkAssignCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkAssignCaptures>>,
    TError,
    { customerId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkAssignCaptures>>,
  TError,
  { customerId: number; data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkAssignCaptures>>,
    { customerId: number; data: BodyType<number[]> }
  > = (props) => {
    const { customerId, data } = props ?? {};

    return bulkAssignCaptures(customerId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkAssignCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkAssignCaptures>>
>;
export type BulkAssignCapturesMutationBody = BodyType<number[]>;
export type BulkAssignCapturesMutationError = ErrorType<void>;

export const useBulkAssignCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkAssignCaptures>>,
    TError,
    { customerId: number; data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof bulkAssignCaptures>>,
  TError,
  { customerId: number; data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getBulkAssignCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCaptureLowResJob = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/${encodeURIComponent(String(id))}/LowRes`,
      method: 'POST',
    },
    options
  );
};

export const getPostCaptureLowResJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureLowResJob>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureLowResJob>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureLowResJob>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return postCaptureLowResJob(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureLowResJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureLowResJob>>
>;

export type PostCaptureLowResJobMutationError = ErrorType<void>;

export const usePostCaptureLowResJob = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureLowResJob>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureLowResJob>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getPostCaptureLowResJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCaptureHighResJob = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/${encodeURIComponent(String(id))}/HighRes`,
      method: 'POST',
    },
    options
  );
};

export const getPostCaptureHighResJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResJob>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureHighResJob>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureHighResJob>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return postCaptureHighResJob(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureHighResJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureHighResJob>>
>;

export type PostCaptureHighResJobMutationError = ErrorType<void>;

export const usePostCaptureHighResJob = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResJob>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureHighResJob>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getPostCaptureHighResJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCaptureHighResWithMlModelJob = (
  id: number,
  engineId: number,
  modelId: number,
  parametersString: BodyType<ParametersString>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/${encodeURIComponent(
        String(id)
      )}/HighRes/Engine/${encodeURIComponent(
        String(engineId)
      )}/Model/${encodeURIComponent(String(modelId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: parametersString,
    },
    options
  );
};

export const getPostCaptureHighResWithMlModelJobMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>,
    TError,
    {
      id: number;
      engineId: number;
      modelId: number;
      data: BodyType<ParametersString>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>,
  TError,
  {
    id: number;
    engineId: number;
    modelId: number;
    data: BodyType<ParametersString>;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>,
    {
      id: number;
      engineId: number;
      modelId: number;
      data: BodyType<ParametersString>;
    }
  > = (props) => {
    const { id, engineId, modelId, data } = props ?? {};

    return postCaptureHighResWithMlModelJob(
      id,
      engineId,
      modelId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureHighResWithMlModelJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>
>;
export type PostCaptureHighResWithMlModelJobMutationBody =
  BodyType<ParametersString>;
export type PostCaptureHighResWithMlModelJobMutationError = ErrorType<void>;

export const usePostCaptureHighResWithMlModelJob = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>,
    TError,
    {
      id: number;
      engineId: number;
      modelId: number;
      data: BodyType<ParametersString>;
    },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureHighResWithMlModelJob>>,
  TError,
  {
    id: number;
    engineId: number;
    modelId: number;
    data: BodyType<ParametersString>;
  },
  TContext
> => {
  const mutationOptions =
    getPostCaptureHighResWithMlModelJobMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCaptureHighResWithNoModel = (
  id: number,
  engineId: number,
  parametersString: BodyType<ParametersString>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/${encodeURIComponent(
        String(id)
      )}/HighRes/Engine/${encodeURIComponent(String(engineId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: parametersString,
    },
    options
  );
};

export const getPostCaptureHighResWithNoModelMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>,
    TError,
    { id: number; engineId: number; data: BodyType<ParametersString> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>,
  TError,
  { id: number; engineId: number; data: BodyType<ParametersString> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>,
    { id: number; engineId: number; data: BodyType<ParametersString> }
  > = (props) => {
    const { id, engineId, data } = props ?? {};

    return postCaptureHighResWithNoModel(id, engineId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureHighResWithNoModelMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>
>;
export type PostCaptureHighResWithNoModelMutationBody =
  BodyType<ParametersString>;
export type PostCaptureHighResWithNoModelMutationError = ErrorType<void>;

export const usePostCaptureHighResWithNoModel = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>,
    TError,
    { id: number; engineId: number; data: BodyType<ParametersString> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureHighResWithNoModel>>,
  TError,
  { id: number; engineId: number; data: BodyType<ParametersString> },
  TContext
> => {
  const mutationOptions =
    getPostCaptureHighResWithNoModelMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCapture = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Capture>(
    {
      url: `/api/Capture/${encodeURIComponent(String(captureId))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCapture>>,
    TError,
    { captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCapture>>,
  TError,
  { captureId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCapture>>,
    { captureId: number }
  > = (props) => {
    const { captureId } = props ?? {};

    return deleteCapture(captureId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCapture>>
>;

export type DeleteCaptureMutationError = ErrorType<void>;

export const useDeleteCapture = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCapture>>,
    TError,
    { captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCapture>>,
  TError,
  { captureId: number },
  TContext
> => {
  const mutationOptions = getDeleteCaptureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const bulkRecoverCapture = (
  bulkRecoverCaptureBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/Recover`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkRecoverCaptureBody,
    },
    options
  );
};

export const getBulkRecoverCaptureMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkRecoverCapture>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkRecoverCapture>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkRecoverCapture>>,
    { data: BodyType<number[]> }
  > = (props) => {
    const { data } = props ?? {};

    return bulkRecoverCapture(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkRecoverCaptureMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkRecoverCapture>>
>;
export type BulkRecoverCaptureMutationBody = BodyType<number[]>;
export type BulkRecoverCaptureMutationError = ErrorType<void>;

export const useBulkRecoverCapture = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkRecoverCapture>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof bulkRecoverCapture>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getBulkRecoverCaptureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const cloneCaptures = (
  captureCloneRequest: BodyType<CaptureCloneRequest>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Capture/Clone`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureCloneRequest,
    },
    options
  );
};

export const getCloneCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneCaptures>>,
    TError,
    { data: BodyType<CaptureCloneRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cloneCaptures>>,
  TError,
  { data: BodyType<CaptureCloneRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cloneCaptures>>,
    { data: BodyType<CaptureCloneRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return cloneCaptures(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloneCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof cloneCaptures>>
>;
export type CloneCapturesMutationBody = BodyType<CaptureCloneRequest>;
export type CloneCapturesMutationError = ErrorType<void>;

export const useCloneCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cloneCaptures>>,
    TError,
    { data: BodyType<CaptureCloneRequest> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cloneCaptures>>,
  TError,
  { data: BodyType<CaptureCloneRequest> },
  TContext
> => {
  const mutationOptions = getCloneCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const exportCaptures = (
  exportCapturesBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureExport[]>(
    {
      url: `/api/Capture/Export`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: exportCapturesBody,
    },
    options
  );
};

export const getExportCapturesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportCaptures>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportCaptures>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportCaptures>>,
    { data: BodyType<number[]> }
  > = (props) => {
    const { data } = props ?? {};

    return exportCaptures(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportCapturesMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportCaptures>>
>;
export type ExportCapturesMutationBody = BodyType<number[]>;
export type ExportCapturesMutationError = ErrorType<void>;

export const useExportCaptures = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportCaptures>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof exportCaptures>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getExportCapturesMutationOptions(options);

  return useMutation(mutationOptions);
};
