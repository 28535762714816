/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { FarmReport } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getFileReportById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<FarmReport>(
    {
      url: `/api/FileReport/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetFileReportByIdQueryKey = (id: number) => {
  return [`/api/FileReport/${id}`] as const;
};

export const getGetFileReportByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getFileReportById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFileReportById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFileReportByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFileReportById>>
  > = ({ signal }) => getFileReportById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFileReportById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFileReportByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileReportById>>
>;
export type GetFileReportByIdQueryError = ErrorType<void>;

export const useGetFileReportById = <
  TData = Awaited<ReturnType<typeof getFileReportById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFileReportById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFileReportByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFileReportDataById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/FileReport/${encodeURIComponent(String(id))}/Data`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getGetFileReportDataByIdQueryKey = (id: number) => {
  return [`/api/FileReport/${id}/Data`] as const;
};

export const getGetFileReportDataByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getFileReportDataById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFileReportDataById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFileReportDataByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFileReportDataById>>
  > = ({ signal }) => getFileReportDataById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFileReportDataById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFileReportDataByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileReportDataById>>
>;
export type GetFileReportDataByIdQueryError = ErrorType<void>;

export const useGetFileReportDataById = <
  TData = Awaited<ReturnType<typeof getFileReportDataById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFileReportDataById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFileReportDataByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
