/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { AnalyticJobType, AnalyticJobTypeFilter } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAnalyticJobType = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticJobType[]>(
    { url: `/api/AnalyticJobTypes`, method: 'GET', signal },
    options
  );
};

export const getGetAnalyticJobTypeQueryKey = () => {
  return [`/api/AnalyticJobTypes`] as const;
};

export const getGetAnalyticJobTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticJobType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAnalyticJobType>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticJobTypeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticJobType>>
  > = ({ signal }) => getAnalyticJobType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticJobType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticJobTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticJobType>>
>;
export type GetAnalyticJobTypeQueryError = ErrorType<void>;

export const useGetAnalyticJobType = <
  TData = Awaited<ReturnType<typeof getAnalyticJobType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAnalyticJobType>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticJobTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postAnalyticJobType = (
  analyticJobType: BodyType<AnalyticJobType>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticJobType>(
    {
      url: `/api/AnalyticJobTypes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: analyticJobType,
    },
    options
  );
};

export const getPostAnalyticJobTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalyticJobType>>,
    TError,
    { data: BodyType<AnalyticJobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAnalyticJobType>>,
  TError,
  { data: BodyType<AnalyticJobType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAnalyticJobType>>,
    { data: BodyType<AnalyticJobType> }
  > = (props) => {
    const { data } = props ?? {};

    return postAnalyticJobType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAnalyticJobTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAnalyticJobType>>
>;
export type PostAnalyticJobTypeMutationBody = BodyType<AnalyticJobType>;
export type PostAnalyticJobTypeMutationError = ErrorType<void>;

export const usePostAnalyticJobType = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAnalyticJobType>>,
    TError,
    { data: BodyType<AnalyticJobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAnalyticJobType>>,
  TError,
  { data: BodyType<AnalyticJobType> },
  TContext
> => {
  const mutationOptions = getPostAnalyticJobTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAnalyticJobTypeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticJobType>(
    {
      url: `/api/AnalyticJobTypes/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticJobTypeByIdQueryKey = (id: number) => {
  return [`/api/AnalyticJobTypes/${id}`] as const;
};

export const getGetAnalyticJobTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAnalyticJobTypeByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticJobTypeById>>
  > = ({ signal }) => getAnalyticJobTypeById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticJobTypeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticJobTypeByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticJobTypeById>>
>;
export type GetAnalyticJobTypeByIdQueryError = ErrorType<void>;

export const useGetAnalyticJobTypeById = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticJobTypeByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putAnalyticJobTypeById = (
  id: number,
  analyticJobType: BodyType<AnalyticJobType>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticJobType>(
    {
      url: `/api/AnalyticJobTypes/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: analyticJobType,
    },
    options
  );
};

export const getPutAnalyticJobTypeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticJobTypeById>>,
    TError,
    { id: number; data: BodyType<AnalyticJobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putAnalyticJobTypeById>>,
  TError,
  { id: number; data: BodyType<AnalyticJobType> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putAnalyticJobTypeById>>,
    { id: number; data: BodyType<AnalyticJobType> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putAnalyticJobTypeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutAnalyticJobTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putAnalyticJobTypeById>>
>;
export type PutAnalyticJobTypeByIdMutationBody = BodyType<AnalyticJobType>;
export type PutAnalyticJobTypeByIdMutationError = ErrorType<void>;

export const usePutAnalyticJobTypeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putAnalyticJobTypeById>>,
    TError,
    { id: number; data: BodyType<AnalyticJobType> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putAnalyticJobTypeById>>,
  TError,
  { id: number; data: BodyType<AnalyticJobType> },
  TContext
> => {
  const mutationOptions = getPutAnalyticJobTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteAnalyticJobTypeById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticJobType>(
    {
      url: `/api/AnalyticJobTypes/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteAnalyticJobTypeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAnalyticJobTypeById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAnalyticJobTypeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>
>;

export type DeleteAnalyticJobTypeByIdMutationError = ErrorType<void>;

export const useDeleteAnalyticJobTypeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAnalyticJobTypeById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteAnalyticJobTypeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAnalyticJobTypeByAnalyticId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticJobType[]>(
    {
      url: `/api/AnalyticJobTypes/Analytic/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticJobTypeByAnalyticIdQueryKey = (id: number) => {
  return [`/api/AnalyticJobTypes/Analytic/${id}`] as const;
};

export const getGetAnalyticJobTypeByAnalyticIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAnalyticJobTypeByAnalyticIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>
  > = ({ signal }) =>
    getAnalyticJobTypeByAnalyticId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticJobTypeByAnalyticIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>
>;
export type GetAnalyticJobTypeByAnalyticIdQueryError = ErrorType<void>;

export const useGetAnalyticJobTypeByAnalyticId = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeByAnalyticId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticJobTypeByAnalyticIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticJobTypeByJobTypeId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<AnalyticJobType>(
    {
      url: `/api/AnalyticJobTypes/JobType/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetAnalyticJobTypeByJobTypeIdQueryKey = (id: number) => {
  return [`/api/AnalyticJobTypes/JobType/${id}`] as const;
};

export const getGetAnalyticJobTypeByJobTypeIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAnalyticJobTypeByJobTypeIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>
  > = ({ signal }) => getAnalyticJobTypeByJobTypeId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticJobTypeByJobTypeIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>
>;
export type GetAnalyticJobTypeByJobTypeIdQueryError = ErrorType<void>;

export const useGetAnalyticJobTypeByJobTypeId = <
  TData = Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnalyticJobTypeByJobTypeId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticJobTypeByJobTypeIdQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredAnalyticJobTypes = (
  skip: number,
  take: number,
  analyticJobTypeFilter: BodyType<AnalyticJobTypeFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<AnalyticJobType[]>(
    {
      url: `/api/AnalyticJobTypes/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: analyticJobTypeFilter,
    },
    options
  );
};

export const getGetFilteredAnalyticJobTypesMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>,
    TError,
    { skip: number; take: number; data: BodyType<AnalyticJobTypeFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>,
  TError,
  { skip: number; take: number; data: BodyType<AnalyticJobTypeFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>,
    { skip: number; take: number; data: BodyType<AnalyticJobTypeFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredAnalyticJobTypes(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredAnalyticJobTypesMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>
>;
export type GetFilteredAnalyticJobTypesMutationBody =
  BodyType<AnalyticJobTypeFilter>;
export type GetFilteredAnalyticJobTypesMutationError = ErrorType<void>;

export const useGetFilteredAnalyticJobTypes = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>,
    TError,
    { skip: number; take: number; data: BodyType<AnalyticJobTypeFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredAnalyticJobTypes>>,
  TError,
  { skip: number; take: number; data: BodyType<AnalyticJobTypeFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredAnalyticJobTypesMutationOptions(options);

  return useMutation(mutationOptions);
};
