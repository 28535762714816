/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Category } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCategory = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Category[]>(
    { url: `/api/Categories`, method: 'GET', signal },
    options
  );
};

export const getGetCategoryQueryKey = () => {
  return [`/api/Categories`] as const;
};

export const getGetCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getCategory>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategory>>> = ({
    signal,
  }) => getCategory(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCategoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategory>>
>;
export type GetCategoryQueryError = ErrorType<void>;

export const useGetCategory = <
  TData = Awaited<ReturnType<typeof getCategory>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCategory>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCategoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCategory = (
  category: BodyType<Category>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Category>(
    {
      url: `/api/Categories`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: category,
    },
    options
  );
};

export const getPostCategoryMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCategory>>,
    TError,
    { data: BodyType<Category> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCategory>>,
  TError,
  { data: BodyType<Category> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCategory>>,
    { data: BodyType<Category> }
  > = (props) => {
    const { data } = props ?? {};

    return postCategory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCategory>>
>;
export type PostCategoryMutationBody = BodyType<Category>;
export type PostCategoryMutationError = ErrorType<void>;

export const usePostCategory = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCategory>>,
    TError,
    { data: BodyType<Category> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCategory>>,
  TError,
  { data: BodyType<Category> },
  TContext
> => {
  const mutationOptions = getPostCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCategoryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Category>(
    {
      url: `/api/Categories/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCategoryByIdQueryKey = (id: number) => {
  return [`/api/Categories/${id}`] as const;
};

export const getGetCategoryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCategoryById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCategoryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategoryByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoryById>>> = ({
    signal,
  }) => getCategoryById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCategoryById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCategoryByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategoryById>>
>;
export type GetCategoryByIdQueryError = ErrorType<void>;

export const useGetCategoryById = <
  TData = Awaited<ReturnType<typeof getCategoryById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCategoryById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCategoryByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCategoryById = (
  id: number,
  category: BodyType<Category>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Category>(
    {
      url: `/api/Categories/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: category,
    },
    options
  );
};

export const getPutCategoryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCategoryById>>,
    TError,
    { id: number; data: BodyType<Category> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCategoryById>>,
  TError,
  { id: number; data: BodyType<Category> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCategoryById>>,
    { id: number; data: BodyType<Category> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCategoryById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCategoryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCategoryById>>
>;
export type PutCategoryByIdMutationBody = BodyType<Category>;
export type PutCategoryByIdMutationError = ErrorType<void>;

export const usePutCategoryById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCategoryById>>,
    TError,
    { id: number; data: BodyType<Category> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCategoryById>>,
  TError,
  { id: number; data: BodyType<Category> },
  TContext
> => {
  const mutationOptions = getPutCategoryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCategoryById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Category>(
    {
      url: `/api/Categories/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCategoryByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCategoryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCategoryById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCategoryById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCategoryById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCategoryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCategoryById>>
>;

export type DeleteCategoryByIdMutationError = ErrorType<void>;

export const useDeleteCategoryById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCategoryById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCategoryById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCategoryByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCategoriesByCustomerId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Category[]>(
    {
      url: `/api/Categories/Customer/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCategoriesByCustomerIdQueryKey = (id: number) => {
  return [`/api/Categories/Customer/${id}`] as const;
};

export const getGetCategoriesByCustomerIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCategoriesByCustomerId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCategoriesByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCategoriesByCustomerIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCategoriesByCustomerId>>
  > = ({ signal }) => getCategoriesByCustomerId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCategoriesByCustomerId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCategoriesByCustomerIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCategoriesByCustomerId>>
>;
export type GetCategoriesByCustomerIdQueryError = ErrorType<void>;

export const useGetCategoriesByCustomerId = <
  TData = Awaited<ReturnType<typeof getCategoriesByCustomerId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCategoriesByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCategoriesByCustomerIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
