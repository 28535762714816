/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { ServicesHealthCheck } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const healthCheck = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<string>(
    { url: `/api/healthz`, method: 'GET', signal },
    options
  );
};

export const getHealthCheckQueryKey = () => {
  return [`/api/healthz`] as const;
};

export const getHealthCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthCheckQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({
    signal,
  }) => healthCheck(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof healthCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HealthCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthCheck>>
>;
export type HealthCheckQueryError = ErrorType<unknown>;

export const useHealthCheck = <
  TData = Awaited<ReturnType<typeof healthCheck>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getHealthCheckQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const servicesHealthCheck = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<ServicesHealthCheck>(
    { url: `/api/healthz/services`, method: 'GET', signal },
    options
  );
};

export const getServicesHealthCheckQueryKey = () => {
  return [`/api/healthz/services`] as const;
};

export const getServicesHealthCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof servicesHealthCheck>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof servicesHealthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getServicesHealthCheckQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof servicesHealthCheck>>
  > = ({ signal }) => servicesHealthCheck(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof servicesHealthCheck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ServicesHealthCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof servicesHealthCheck>>
>;
export type ServicesHealthCheckQueryError = ErrorType<void>;

export const useServicesHealthCheck = <
  TData = Awaited<ReturnType<typeof servicesHealthCheck>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof servicesHealthCheck>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getServicesHealthCheckQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
