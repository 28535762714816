/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureJobRating } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const createCaptureJobRating = (
  captureJobRating: BodyType<CaptureJobRating>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<string>(
    {
      url: `/api/CaptureJobRating`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureJobRating,
    },
    options
  );
};

export const getCreateCaptureJobRatingMutationOptions = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCaptureJobRating>>,
    TError,
    { data: BodyType<CaptureJobRating> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCaptureJobRating>>,
  TError,
  { data: BodyType<CaptureJobRating> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCaptureJobRating>>,
    { data: BodyType<CaptureJobRating> }
  > = (props) => {
    const { data } = props ?? {};

    return createCaptureJobRating(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCaptureJobRatingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCaptureJobRating>>
>;
export type CreateCaptureJobRatingMutationBody = BodyType<CaptureJobRating>;
export type CreateCaptureJobRatingMutationError = ErrorType<string | void>;

export const useCreateCaptureJobRating = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCaptureJobRating>>,
    TError,
    { data: BodyType<CaptureJobRating> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCaptureJobRating>>,
  TError,
  { data: BodyType<CaptureJobRating> },
  TContext
> => {
  const mutationOptions = getCreateCaptureJobRatingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateCaptureJobRating = (
  captureJobRating: BodyType<CaptureJobRating>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<string>(
    {
      url: `/api/CaptureJobRating`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureJobRating,
    },
    options
  );
};

export const getUpdateCaptureJobRatingMutationOptions = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureJobRating>>,
    TError,
    { data: BodyType<CaptureJobRating> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCaptureJobRating>>,
  TError,
  { data: BodyType<CaptureJobRating> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCaptureJobRating>>,
    { data: BodyType<CaptureJobRating> }
  > = (props) => {
    const { data } = props ?? {};

    return updateCaptureJobRating(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCaptureJobRatingMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCaptureJobRating>>
>;
export type UpdateCaptureJobRatingMutationBody = BodyType<CaptureJobRating>;
export type UpdateCaptureJobRatingMutationError = ErrorType<string | void>;

export const useUpdateCaptureJobRating = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCaptureJobRating>>,
    TError,
    { data: BodyType<CaptureJobRating> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCaptureJobRating>>,
  TError,
  { data: BodyType<CaptureJobRating> },
  TContext
> => {
  const mutationOptions = getUpdateCaptureJobRatingMutationOptions(options);

  return useMutation(mutationOptions);
};
