/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureTag } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureTag = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureTag[]>(
    { url: `/api/CaptureTags`, method: 'GET', signal },
    options
  );
};

export const getGetCaptureTagQueryKey = () => {
  return [`/api/CaptureTags`] as const;
};

export const getGetCaptureTagQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureTag>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureTag>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureTagQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaptureTag>>> = ({
    signal,
  }) => getCaptureTag(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureTag>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureTagQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureTag>>
>;
export type GetCaptureTagQueryError = ErrorType<void>;

export const useGetCaptureTag = <
  TData = Awaited<ReturnType<typeof getCaptureTag>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCaptureTag>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureTagQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCaptureTag = (
  captureTag: BodyType<CaptureTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureTag>(
    {
      url: `/api/CaptureTags`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureTag,
    },
    options
  );
};

export const getPostCaptureTagMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureTag>>,
    TError,
    { data: BodyType<CaptureTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureTag>>,
  TError,
  { data: BodyType<CaptureTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureTag>>,
    { data: BodyType<CaptureTag> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureTag(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureTag>>
>;
export type PostCaptureTagMutationBody = BodyType<CaptureTag>;
export type PostCaptureTagMutationError = ErrorType<void>;

export const usePostCaptureTag = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureTag>>,
    TError,
    { data: BodyType<CaptureTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureTag>>,
  TError,
  { data: BodyType<CaptureTag> },
  TContext
> => {
  const mutationOptions = getPostCaptureTagMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureTag>(
    {
      url: `/api/CaptureTags/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureTagByIdQueryKey = (id: number) => {
  return [`/api/CaptureTags/${id}`] as const;
};

export const getGetCaptureTagByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaptureTagByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureTagById>>
  > = ({ signal }) => getCaptureTagById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureTagById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureTagByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureTagById>>
>;
export type GetCaptureTagByIdQueryError = ErrorType<void>;

export const useGetCaptureTagById = <
  TData = Awaited<ReturnType<typeof getCaptureTagById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureTagByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCaptureTagById = (
  id: number,
  captureTag: BodyType<CaptureTag>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureTag>(
    {
      url: `/api/CaptureTags/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureTag,
    },
    options
  );
};

export const getPutCaptureTagByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureTagById>>,
    TError,
    { id: number; data: BodyType<CaptureTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureTagById>>,
  TError,
  { id: number; data: BodyType<CaptureTag> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureTagById>>,
    { id: number; data: BodyType<CaptureTag> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureTagById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureTagByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureTagById>>
>;
export type PutCaptureTagByIdMutationBody = BodyType<CaptureTag>;
export type PutCaptureTagByIdMutationError = ErrorType<void>;

export const usePutCaptureTagById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureTagById>>,
    TError,
    { id: number; data: BodyType<CaptureTag> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureTagById>>,
  TError,
  { id: number; data: BodyType<CaptureTag> },
  TContext
> => {
  const mutationOptions = getPutCaptureTagByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureTagById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureTag>(
    {
      url: `/api/CaptureTags/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureTagByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureTagById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureTagById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureTagById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureTagById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureTagByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureTagById>>
>;

export type DeleteCaptureTagByIdMutationError = ErrorType<void>;

export const useDeleteCaptureTagById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureTagById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureTagById>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCaptureTagByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCaptureTagsByCustomerId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureTag[]>(
    {
      url: `/api/CaptureTags/Customer/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureTagsByCustomerIdQueryKey = (id: number) => {
  return [`/api/CaptureTags/Customer/${id}`] as const;
};

export const getGetCaptureTagsByCustomerIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureTagsByCustomerIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>
  > = ({ signal }) => getCaptureTagsByCustomerId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureTagsByCustomerIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>
>;
export type GetCaptureTagsByCustomerIdQueryError = ErrorType<void>;

export const useGetCaptureTagsByCustomerId = <
  TData = Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagsByCustomerId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureTagsByCustomerIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaptureTagsByProjectId = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureTag[]>(
    {
      url: `/api/CaptureTags/Project/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureTagsByProjectIdQueryKey = (id: number) => {
  return [`/api/CaptureTags/Project/${id}`] as const;
};

export const getGetCaptureTagsByProjectIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureTagsByProjectId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagsByProjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaptureTagsByProjectIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureTagsByProjectId>>
  > = ({ signal }) => getCaptureTagsByProjectId(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureTagsByProjectId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureTagsByProjectIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureTagsByProjectId>>
>;
export type GetCaptureTagsByProjectIdQueryError = ErrorType<void>;

export const useGetCaptureTagsByProjectId = <
  TData = Awaited<ReturnType<typeof getCaptureTagsByProjectId>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureTagsByProjectId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureTagsByProjectIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
