export interface IAgerStoreSidebarsSlice {
  navSideBarOpen: boolean;
  setNavSideBarOpen: (open: boolean) => void;
  detailsSideBarOpen: boolean;
  setDetailsSideBarOpen: (open: boolean) => void;
}

export const initialAgerStoreSidebarsSlice: IAgerStoreSidebarsSlice = {
  navSideBarOpen: false,
  setNavSideBarOpen: () => {
    throw new Error('AgerStore not initialized');
  },
  detailsSideBarOpen: false,
  setDetailsSideBarOpen: () => {
    throw new Error('AgerStore not initialized');
  },
};
