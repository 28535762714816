import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError, AxiosRequestHeaders, isAxiosError } from 'axios';
import { useAuth } from 'oidc-react';
import { useEffect, useRef, useState } from 'react';

import { APIUtils, AXIOS_INSTANCE } from '@agerpoint/api';
import { Intermediate } from '@agerpoint/feature';

interface ReactQuerySetupProps {
  children: JSX.Element | null;
}

export const ReactQuerySetup = ({ children }: ReactQuerySetupProps) => {
  const { userData, signIn, signOut } = useAuth();

  const [requestInterceptorReady, setRequestInterceptorReady] = useState(false);
  const [responseInterceptorReady, setResponseInterceptorReady] =
    useState(false);

  const debugMode = useRef<boolean>(true);

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: APIUtils.getDuration({
            minutes: 1,
          }),
          gcTime: APIUtils.getDuration({
            minutes: 5,
          }),
          retry: (failureCount, error) => {
            if (failureCount > 3) {
              return false;
            }
            if (
              isAxiosError(error) &&
              ([400, 401, 403, 404].includes(error.response?.status ?? 0) ||
                error.code === APIUtils.AgerError.BAD_REQUEST)
            ) {
              return false;
            }

            return true;
          },
        },
      },
    })
  );

  useEffect(() => {
    if (userData?.access_token === undefined) {
      return () => {
        //
      };
    }

    const interceptorId = AXIOS_INSTANCE.interceptors.request.use((config) => {
      const headers: AxiosRequestHeaders = config.headers;

      headers.Authorization = `Bearer ${userData?.access_token}`;

      return {
        ...config,
        headers,
      };
    });

    setRequestInterceptorReady(true);

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [userData?.access_token]);

  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          signOut();
          signIn();
        }

        return Promise.reject(error);
      }
    );

    setResponseInterceptorReady(true);

    return () => {
      AXIOS_INSTANCE.interceptors.response.eject(interceptorId);
    };
  }, [signIn, signOut]);

  if (!requestInterceptorReady || !responseInterceptorReady) {
    return <Intermediate message="Checking for authentication ..." />;
  }

  return (
    <QueryClientProvider client={queryClient.current}>
      <QueryGarbageCollector>
        <>
          {children}
          {debugMode.current && <ReactQueryDevtools initialIsOpen={false} />}
        </>
      </QueryGarbageCollector>
    </QueryClientProvider>
  );
};

export const ReactQueryNoAuthSetup = ({ children }: ReactQuerySetupProps) => {
  const debugMode = useRef<boolean>(true);

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: APIUtils.getDuration({
            minutes: 1,
          }),
          gcTime: APIUtils.getDuration({
            minutes: 5,
          }),
          retry: (failureCount, error) => {
            if (failureCount > 3) {
              return false;
            }

            if (
              isAxiosError(error) &&
              ([400, 401, 403, 404].includes(error.response?.status ?? 0) ||
                error.code === APIUtils.AgerError.BAD_REQUEST)
            ) {
              return false;
            }

            return true;
          },
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient.current}>
      <QueryGarbageCollector>
        <>
          {children}
          {debugMode.current && <ReactQueryDevtools initialIsOpen={false} />}
        </>
      </QueryGarbageCollector>
    </QueryClientProvider>
  );
};

interface QueryCustomGarbageCollectorProps {
  children: JSX.Element;
}

const QueryGarbageCollector = ({
  children,
}: QueryCustomGarbageCollectorProps) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const unsub = queryClient.getQueryCache().subscribe((event) => {
      if (
        event.type === 'removed' &&
        event.query.queryKey.includes(APIUtils.QueryKey.thumborImage)
      ) {
        const url = event.query.state.data;
        URL.revokeObjectURL(url);
      }

      // if (event.type === 'added' && event.query.queryHash.includes('null')) {
      //   queryClient.removeQueries({
      //     predicate: (query) => query.queryHash === event.query.queryHash,
      //   });
      // }
    });

    return unsub;
  }, [queryClient]);

  return children;
};
