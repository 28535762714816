/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { CaptureSubjectPreset } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCaptureSubjectPresetsByCaptureId = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CaptureSubjectPreset>(
    {
      url: `/api/CaptureSubjectPresets/Capture/${encodeURIComponent(
        String(captureId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCaptureSubjectPresetsByCaptureIdQueryKey = (
  captureId: number
) => {
  return [`/api/CaptureSubjectPresets/Capture/${captureId}`] as const;
};

export const getGetCaptureSubjectPresetsByCaptureIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCaptureSubjectPresetsByCaptureIdQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>
  > = ({ signal }) =>
    getCaptureSubjectPresetsByCaptureId(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaptureSubjectPresetsByCaptureIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>
>;
export type GetCaptureSubjectPresetsByCaptureIdQueryError = ErrorType<void>;

export const useGetCaptureSubjectPresetsByCaptureId = <
  TData = Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCaptureSubjectPresetsByCaptureId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptureSubjectPresetsByCaptureIdQueryOptions(
    captureId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
