/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */

export type PrecisionModels =
  (typeof PrecisionModels)[keyof typeof PrecisionModels];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrecisionModels = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;
