/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Project, ProjectFilter, ProjectsCaptures } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getProject = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Project[]>(
    { url: `/api/Projects`, method: 'GET', signal },
    options
  );
};

export const getGetProjectQueryKey = () => {
  return [`/api/Projects`] as const;
};

export const getGetProjectQueryOptions = <
  TData = Awaited<ReturnType<typeof getProject>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProject>>> = ({
    signal,
  }) => getProject(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProject>>
>;
export type GetProjectQueryError = ErrorType<void>;

export const useGetProject = <
  TData = Awaited<ReturnType<typeof getProject>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postProject = (
  project: BodyType<Project>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Project>(
    {
      url: `/api/Projects`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: project,
    },
    options
  );
};

export const getPostProjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProject>>,
    TError,
    { data: BodyType<Project> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProject>>,
  TError,
  { data: BodyType<Project> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProject>>,
    { data: BodyType<Project> }
  > = (props) => {
    const { data } = props ?? {};

    return postProject(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProject>>
>;
export type PostProjectMutationBody = BodyType<Project>;
export type PostProjectMutationError = ErrorType<void>;

export const usePostProject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProject>>,
    TError,
    { data: BodyType<Project> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProject>>,
  TError,
  { data: BodyType<Project> },
  TContext
> => {
  const mutationOptions = getPostProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getFilteredPageProjects = (
  skip: number,
  take: number,
  projectFilter: BodyType<ProjectFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Project[]>(
    {
      url: `/api/Projects/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectFilter,
    },
    options
  );
};

export const getGetFilteredPageProjectsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPageProjects>>,
    TError,
    { skip: number; take: number; data: BodyType<ProjectFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPageProjects>>,
  TError,
  { skip: number; take: number; data: BodyType<ProjectFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPageProjects>>,
    { skip: number; take: number; data: BodyType<ProjectFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPageProjects(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPageProjectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPageProjects>>
>;
export type GetFilteredPageProjectsMutationBody = BodyType<ProjectFilter>;
export type GetFilteredPageProjectsMutationError = ErrorType<void>;

export const useGetFilteredPageProjects = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPageProjects>>,
    TError,
    { skip: number; take: number; data: BodyType<ProjectFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPageProjects>>,
  TError,
  { skip: number; take: number; data: BodyType<ProjectFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredPageProjectsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getProjectByUuid = (
  uuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Project>(
    {
      url: `/api/Projects/${encodeURIComponent(String(uuid))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectByUuidQueryKey = (uuid: string) => {
  return [`/api/Projects/${uuid}`] as const;
};

export const getGetProjectByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectByUuidQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectByUuid>>
  > = ({ signal }) => getProjectByUuid(uuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectByUuid>>
>;
export type GetProjectByUuidQueryError = ErrorType<void>;

export const useGetProjectByUuid = <
  TData = Awaited<ReturnType<typeof getProjectByUuid>>,
  TError = ErrorType<void>
>(
  uuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectByUuid>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectByUuidQueryOptions(uuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putProject = (
  uuid: string,
  project: BodyType<Project>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Project>(
    {
      url: `/api/Projects/${encodeURIComponent(String(uuid))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: project,
    },
    options
  );
};

export const getPutProjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProject>>,
    TError,
    { uuid: string; data: BodyType<Project> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProject>>,
  TError,
  { uuid: string; data: BodyType<Project> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProject>>,
    { uuid: string; data: BodyType<Project> }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return putProject(uuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProject>>
>;
export type PutProjectMutationBody = BodyType<Project>;
export type PutProjectMutationError = ErrorType<void>;

export const usePutProject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProject>>,
    TError,
    { uuid: string; data: BodyType<Project> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProject>>,
  TError,
  { uuid: string; data: BodyType<Project> },
  TContext
> => {
  const mutationOptions = getPutProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteProject = (
  uuid: string,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Projects/${encodeURIComponent(String(uuid))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteProjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProject>>,
    TError,
    { uuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProject>>,
  TError,
  { uuid: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProject>>,
    { uuid: string }
  > = (props) => {
    const { uuid } = props ?? {};

    return deleteProject(uuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProject>>
>;

export type DeleteProjectMutationError = ErrorType<void>;

export const useDeleteProject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProject>>,
    TError,
    { uuid: string },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProject>>,
  TError,
  { uuid: string },
  TContext
> => {
  const mutationOptions = getDeleteProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getProjectsByCapture = (
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Project[]>(
    {
      url: `/api/Projects/Capture/${encodeURIComponent(String(captureId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetProjectsByCaptureQueryKey = (captureId: number) => {
  return [`/api/Projects/Capture/${captureId}`] as const;
};

export const getGetProjectsByCaptureQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectsByCapture>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectsByCapture>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectsByCaptureQueryKey(captureId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectsByCapture>>
  > = ({ signal }) => getProjectsByCapture(captureId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!captureId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectsByCapture>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectsByCaptureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectsByCapture>>
>;
export type GetProjectsByCaptureQueryError = ErrorType<void>;

export const useGetProjectsByCapture = <
  TData = Awaited<ReturnType<typeof getProjectsByCapture>>,
  TError = ErrorType<void>
>(
  captureId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectsByCapture>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectsByCaptureQueryOptions(captureId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addCaptureToProject = (
  projectsCaptures: BodyType<ProjectsCaptures>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Projects/Captures/Bulk`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectsCaptures,
    },
    options
  );
};

export const getAddCaptureToProjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCaptureToProject>>,
    TError,
    { data: BodyType<ProjectsCaptures> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addCaptureToProject>>,
  TError,
  { data: BodyType<ProjectsCaptures> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addCaptureToProject>>,
    { data: BodyType<ProjectsCaptures> }
  > = (props) => {
    const { data } = props ?? {};

    return addCaptureToProject(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddCaptureToProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof addCaptureToProject>>
>;
export type AddCaptureToProjectMutationBody = BodyType<ProjectsCaptures>;
export type AddCaptureToProjectMutationError = ErrorType<void>;

export const useAddCaptureToProject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addCaptureToProject>>,
    TError,
    { data: BodyType<ProjectsCaptures> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addCaptureToProject>>,
  TError,
  { data: BodyType<ProjectsCaptures> },
  TContext
> => {
  const mutationOptions = getAddCaptureToProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureFromProject = (
  uuid: string,
  captureId: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<void>(
    {
      url: `/api/Projects/${encodeURIComponent(
        String(uuid)
      )}/Capture/${encodeURIComponent(String(captureId))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureFromProjectMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureFromProject>>,
    TError,
    { uuid: string; captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureFromProject>>,
  TError,
  { uuid: string; captureId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureFromProject>>,
    { uuid: string; captureId: number }
  > = (props) => {
    const { uuid, captureId } = props ?? {};

    return deleteCaptureFromProject(uuid, captureId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureFromProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureFromProject>>
>;

export type DeleteCaptureFromProjectMutationError = ErrorType<void>;

export const useDeleteCaptureFromProject = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureFromProject>>,
    TError,
    { uuid: string; captureId: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureFromProject>>,
  TError,
  { uuid: string; captureId: number },
  TContext
> => {
  const mutationOptions = getDeleteCaptureFromProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
