import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { APIClient, APIUtils } from '../..';
import { uuidRegex } from '../../utils/uuid';

interface IuseGetById {
  captureId?: number;
  enabled?: boolean;
}

export const useGetById = ({ captureId, enabled = true }: IuseGetById) => {
  const queryClient = useQueryClient();

  return APIClient.useGetCaptureById(Number(captureId), {
    query: {
      queryKey: [APIUtils.QueryKey.captures, { captureId: Number(captureId) }],
      enabled: Number.isSafeInteger(Number(captureId)) && enabled,
      initialData: () =>
        APIUtils.searchForInitialValue<APIClient.Capture>({
          queryClient,
          entityKey: APIUtils.QueryKey.captures,
          accessor: 'id',
          id: Number(captureId),
        }),
    },
  });
};

interface IuseGetByPublicUuid {
  capturePublicUuid?: string;
  enabled?: boolean;
}

export const useGetByPublicUuid = ({
  capturePublicUuid,
  enabled = true,
}: IuseGetByPublicUuid) => {
  return APIClient.useGetSharedCaptureByUuid(capturePublicUuid as string, {
    query: {
      queryFn: async () =>
        APIClient.getSharedCaptureByUuid(capturePublicUuid as string),
      queryKey: [APIUtils.QueryKey.sharedCaptures, { capturePublicUuid }],
      enabled:
        !!capturePublicUuid && uuidRegex.test(capturePublicUuid) && enabled,
      select: (data) => data?.[0] ?? null,
    },
  });
};

interface IuseFilteredGet {
  filter?: APIClient.CaptureFilter;
  enabled?: boolean;
}

export const useFilteredGetAll = ({
  filter,
  enabled = true,
}: IuseFilteredGet) => {
  return useInfiniteQuery({
    queryKey: [
      APIUtils.QueryKey.captures,
      APIUtils.QueryKey.infinite,
      { filter },
    ],
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    queryFn: ({ pageParam }) =>
      APIClient.getFilteredPageCaptures(
        pageParam.skip,
        pageParam.take,
        filter as APIClient.CaptureFilter
      ),
    enabled: filter !== undefined && enabled,
  });
};

interface IuseFilteredGetAllIds {
  filter?: APIClient.CaptureFilter;
  enabled?: boolean;
}

export const useFilteredGetAllIds = ({
  filter,
  enabled,
}: IuseFilteredGetAllIds) => {
  return useQuery({
    queryKey: [APIUtils.QueryKey.captureIds, { filter }],
    queryFn: () =>
      APIClient.getFilteredCaptureIds(filter as APIClient.CaptureFilter),
    enabled: filter !== undefined && enabled,
  });
};

interface IuseMultiGet {
  captureIds?: number[];
  enabled?: boolean;
}

export const useMultiGetById = ({
  captureIds,
  enabled = true,
}: IuseMultiGet) => {
  const queryClient = useQueryClient();

  return useQueries({
    queries:
      captureIds?.map((captureId) => ({
        queryFn: () => APIClient.getCaptureById(captureId),
        queryKey: [APIUtils.QueryKey.captures, { captureId: captureId }],
        enabled:
          Number.isSafeInteger(Number(captureId ?? undefined)) && enabled,
        initialData: () =>
          APIUtils.searchForInitialValue<APIClient.Capture>({
            queryClient,
            entityKey: APIUtils.QueryKey.captures,
            accessor: 'id',
            id: Number(captureId),
          }),
      })) ?? [],
  });
};
