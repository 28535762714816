import { useInfiniteQuery } from '@tanstack/react-query';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation.js';
import { Coordinate } from 'ol/coordinate';
import { LineString, MultiPolygon, Polygon } from 'ol/geom';
import Point from 'ol/geom/Point';
import { fromExtent } from 'ol/geom/Polygon';
import Draw, { DrawEvent, createBox } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncyEffect } from 'use-debouncy';

import { APIClient, APIUtils } from '@agerpoint/api';
import {
  useAgerStore,
  useFormValidation,
  useViteToasts,
} from '@agerpoint/utilities';

import { CloudButton } from '../button';
import { ContextMenu } from '../context-menu';
import { CloudInput } from '../input';
import { Modal } from '../modal';
import { CloudOpenlayersMap } from './openlayers-map';
import { ICloudOpenlayersBaseMap } from './openlayers-map.types';
import {
  getBaseMapIcon,
  useCloudOpenlayersMapContext,
} from './openlayers-map.utilities';

interface ICloudOpenlayersMapBaseMapControls {
  id: string;
  baseMap: ICloudOpenlayersBaseMap;
  setBaseMap: (baseMap: ICloudOpenlayersBaseMap) => void;
  availableBaseMapLayers: ICloudOpenlayersBaseMap[];
}

const CloudOpenlayersMapBaseMapControls = ({
  id,
  availableBaseMapLayers,
  baseMap,
  setBaseMap,
}: ICloudOpenlayersMapBaseMapControls) => {
  const { mapInitialized } = useCloudOpenlayersMapContext();

  if (!mapInitialized) {
    return null;
  }

  if (availableBaseMapLayers.length <= 1) {
    return null;
  }

  return (
    <div
      className={`absolute top-4 left-4 z-1 bg-white rounded-lg
    shadow-lg flex flex-row items-center p-1 gap-1`}
    >
      {availableBaseMapLayers.map((layer, index) => {
        let tooltip = '';
        if (layer === 'CanvasLight') {
          tooltip = 'Streets';
        } else if (layer === 'AerialWithLabelsOnDemand') {
          tooltip = 'Satellite and Streets';
        } else if (layer === 'Aerial') {
          tooltip = 'Satellite';
        }

        return (
          <CloudButton.Icon
            key={index}
            id={`${id}-${layer}-base-map-selector`}
            leadingIcon={getBaseMapIcon(layer)}
            tooltip={tooltip}
            toggled={baseMap === layer}
            onClick={() => {
              setBaseMap(layer);
            }}
          />
        );
      })}
    </div>
  );
};

interface ICloudOpenlayersMapViewControls {
  id: string;
  zoom?: {
    delta: number;
    onZoom?: (zoom: number) => void;
  };
  center?: {
    onClick?: () => void;
  };
  location?: {
    userLocation: Coordinate | undefined;
    setUserLocation: (location: Coordinate | undefined) => void;
  };
}

const CloudOpenlayersMapViewControls = ({
  id,
  zoom,
  center,
  location,
}: ICloudOpenlayersMapViewControls) => {
  const { map, mapInitialized } = useCloudOpenlayersMapContext();

  const geolocation = useRef<Geolocation>();

  const geolocationOnChange = useCallback(() => {
    const position = geolocation.current?.getPosition();
    if (position) {
      location?.setUserLocation(position);
    }
  }, [location]);

  useEffect(() => {
    if (!mapInitialized || geolocation.current !== undefined) {
      return;
    }

    const g = new Geolocation({
      projection: map.current?.getView().getProjection(),
      tracking: true,
    });

    geolocation.current = g;

    g.addEventListener('change:position', geolocationOnChange);

    return () => {
      g.removeEventListener('change:position', geolocationOnChange);
      g.dispose();
      geolocation.current = undefined;
    };
  }, [mapInitialized, geolocationOnChange]);

  if (!mapInitialized) {
    return null;
  }

  return (
    <div className="absolute top-4 right-4 z-1 flex flex-col gap-2">
      {zoom && (
        <div className="bg-white rounded-lg shadow-lg flex flex-col gap-1 p-1">
          <CloudButton.Icon
            id={`${id}-zoom-in-button`}
            leadingIcon="plus"
            tooltip="Zoom In"
            tooltipPosition="left"
            onClick={() => {
              const view = map.current?.getView();
              if (!view) {
                return;
              }

              const newZoom = (view.getZoom() ?? 10) + zoom.delta;
              view.cancelAnimations();
              view.animate({
                zoom: newZoom,
                duration: 300,
              });
              zoom.onZoom?.(newZoom);
            }}
          />
          <CloudButton.Icon
            id={`${id}-zoom-out-button`}
            leadingIcon="minus"
            tooltip="Zoom Out"
            tooltipPosition="left"
            onClick={() => {
              const view = map.current?.getView();
              if (!view) {
                return;
              }

              const newZoom = (view.getZoom() ?? 10) - zoom.delta;
              view.cancelAnimations();
              view.animate({
                zoom: newZoom,
                duration: 300,
              });
              zoom.onZoom?.(newZoom);
            }}
          />
        </div>
      )}
      {center && (
        <div className="bg-white rounded-lg shadow-lg p-1">
          <CloudButton.Icon
            id={`${id}-center-button`}
            tooltip="Center View"
            tooltipPosition="left"
            leadingIcon="arrows-to-circle"
            onClick={center.onClick}
          />
        </div>
      )}
      {location?.userLocation && (
        <div className="bg-white rounded-lg shadow-lg p-1">
          <CloudButton.Icon
            id={`${id}-location`}
            tooltip="Your Location"
            tooltipPosition="left"
            leadingIcon="location"
            onClick={() => {
              const view = map.current?.getView();
              if (!view) {
                return;
              }

              view.cancelAnimations();
              view.animate({
                center: location?.userLocation,
                zoom: 17,
                duration: 300,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

interface ICloudOpenlayersAnnotationTools {
  id: string;
  onPointAdded?: (point: Point) => void;
  pointStyle?: StyleLike;
  onLineAdded?: (line: LineString) => void;
  lineStyle?: StyleLike;
  onPolygonAdded?: (polygon: Polygon) => void;
  polygonStyle?: StyleLike;
}

type AnnotationToolTypes = 'point' | 'line' | 'polygon';

const CloudOpenlayersAnnotationTools = ({
  id,
  onPointAdded,
  pointStyle,
  onLineAdded,
  lineStyle,
  onPolygonAdded,
  polygonStyle,
}: ICloudOpenlayersAnnotationTools) => {
  const { map, mapInitialized } = useCloudOpenlayersMapContext();

  const [tool, setTool] = useState<AnnotationToolTypes>();

  const { isMobile } = useAgerStore();

  useEffect(() => {
    const m = map.current;
    if (tool !== 'point' || !mapInitialized || !m) {
      return;
    }
    // Prepare source, layer and draw objects for point placement
    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Point',
      style: pointStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Point> = e.feature as Feature<Point>;
      const point = olFeature.getGeometry();
      if (point) {
        onPointAdded?.(point);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, onPointAdded, pointStyle]);

  useEffect(() => {
    const m = map.current;
    if (tool !== 'line' || !mapInitialized || !m) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      style: lineStyle,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'LineString',
      style: lineStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<LineString> = e.feature as Feature<LineString>;
      const line = olFeature.getGeometry();
      if (line) {
        onLineAdded?.(line);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, lineStyle, onLineAdded]);

  useEffect(() => {
    const m = map.current;
    if (tool !== 'polygon' || !mapInitialized || !m) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      style: polygonStyle,
    });
    const olDraw = new Draw({
      source: olSource,
      type: 'Polygon',
      style: polygonStyle,
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setTool(undefined);
      const olFeature: Feature<Polygon> = e.feature as Feature<Polygon>;
      const polygon = olFeature.getGeometry();
      if (polygon) {
        onPolygonAdded?.(polygon);
      }
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [tool, mapInitialized, polygonStyle, onPolygonAdded]);

  if (!mapInitialized) {
    return null;
  }
  return (
    <div
      className={`${
        isMobile ? 'fixed' : 'absolute'
      } bottom-14 left-1/2 -translate-x-1/2
          z-1 bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1`}
    >
      <CloudButton.Icon
        id={`${id}-2d-point-annotation-button`}
        leadingIcon="location-dot"
        tooltip="Add Point"
        tooltipPosition="top"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'point') {
              setTool(undefined);
            } else {
              setTool('point');
            }
          }
        }}
        toggled={tool === 'point'}
      />

      <CloudButton.Icon
        id={`${id}-2d-line-annotation-button`}
        leadingIcon="ruler"
        tooltip="Add Line"
        tooltipPosition="top"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'line') {
              setTool(undefined);
            } else {
              setTool('line');
            }
          }
        }}
        toggled={tool === 'line'}
      />

      <CloudButton.Icon
        id={`${id}-2d-polygon-annotation-button`}
        leadingIcon="draw-polygon"
        tooltip="Add Polygon"
        tooltipPosition="top"
        onClick={() => {
          if (mapInitialized) {
            if (tool === 'polygon') {
              setTool(undefined);
            } else {
              setTool('polygon');
            }
          }
        }}
        toggled={tool === 'polygon'}
      />
    </div>
  );
};

interface ICloudOpenlayersExtentSelector {
  id: string;
  onPolygonSelected?: (polygon: MultiPolygon | undefined) => void;
  selectedPolygon?: MultiPolygon;
}

const CloudOpenlayersExtentSelector = ({
  id,
  onPolygonSelected,
  selectedPolygon,
}: ICloudOpenlayersExtentSelector) => {
  const toasts = useViteToasts();
  const { map, mapInitialized } = useCloudOpenlayersMapContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showBoundarySelectModal, setShowBoundarySelectModal] = useState(false);
  const [enableSelectionDraw, setEnableSelectionDraw] = useState(false);
  const [boundaryNameFilter, setBoundaryNameFilter] = useState('');
  const [loadingBoundarySearch, setLoadingBoundarySearch] = useState(false);

  const [filter, setFilter] = useState<APIClient.LibraryFilter>();

  const formValidation = useFormValidation();

  const { isMobile } = useAgerStore();

  useEffect(() => {
    if (!showBoundarySelectModal) {
      setBoundaryNameFilter('');
      setSelectedOrganization(undefined);
      setSelectedLibraryItems([]);
      setFilter(undefined);
    }
  }, [showBoundarySelectModal]);

  const organizationsQuery = APIClient.useGetCustomer({
    query: {
      queryKey: [APIUtils.QueryKey.organizations],
      select: (data) => APIUtils.Sort.organizations(data),
    },
  });

  const libraryQuery = useInfiniteQuery({
    queryKey: ['library', APIUtils.QueryKey.infinite, { filter }],
    queryFn: ({ pageParam }) =>
      APIClient.getLibraryFiltered(
        pageParam.skip,
        pageParam.take,
        filter as APIClient.LibraryFilter
      ),
    initialPageParam: APIUtils.defaultInitialPageParam,
    getNextPageParam: APIUtils.defaultGetNextPageParam,
    enabled: filter !== undefined,
  });

  const [selectedOrganization, setSelectedOrganization] =
    useState<APIClient.Customer>();

  useEffect(() => {
    if (organizationsQuery.data?.length === 1) {
      setSelectedOrganization(organizationsQuery.data[0]);
    }
  }, [organizationsQuery.data]);

  useEffect(() => {
    if (!showBoundarySelectModal) {
      return;
    }
    setFilter((prev) => ({
      ...prev,
      name: boundaryNameFilter.trim(),
      customerId: selectedOrganization?.id,
      layerTypeIds: [8],
    }));
  }, [selectedOrganization, showBoundarySelectModal]);

  useDebouncyEffect(
    () => {
      setFilter((prev) => ({
        ...prev,
        name: boundaryNameFilter.trim(),
      }));
    },
    1000,
    [boundaryNameFilter]
  );

  const libraryItems = useMemo(
    () => libraryQuery.data?.pages.flatMap((page) => page) ?? [],
    [libraryQuery.data]
  );

  const [selectedLibraryItems, setSelectedLibraryItems] = useState<
    APIClient.LibraryItem[]
  >([]);

  const searchBySelectedBoundaries = useCallback(async () => {
    setLoadingBoundarySearch(true);

    try {
      const geometriesPromises = selectedLibraryItems.map((item) =>
        APIClient.getGeometriesByGeometryCollectionId(item.entityId as number)
      );

      const geometries = (await Promise.all(geometriesPromises)).flatMap(
        (g) => g
      );

      const apPolygons = geometries
        .filter(
          (g: any) =>
            g?.geom?.type === 'Polygon' || g?.geom?.type === 'MultiPolygon'
        )
        .map((g) => g.geom) as any[];

      const coordinates = [];

      for (const g of apPolygons) {
        if (g.type === 'Polygon') {
          coordinates.push(g.coordinates as Coordinate[][]);
        } else if (g.type === 'MultiPolygon') {
          for (const c of g.coordinates) {
            coordinates.push(c as Coordinate[][]);
          }
        }
      }

      if (coordinates.length === 0) {
        throw new Error('No valid boundaries found');
        return;
      }

      const multiPolygon = new MultiPolygon(coordinates);
      onPolygonSelected?.(multiPolygon);
      setShowBoundarySelectModal(false);
    } catch (e: any) {
      console.error(e);
      toasts.add({
        title: 'Error searching by boundary',
        message: e.message,
        type: 'error',
      });
    } finally {
      setLoadingBoundarySearch(false);
    }
  }, [selectedLibraryItems, onPolygonSelected]);

  useEffect(() => {
    const m = map.current;
    if (!enableSelectionDraw || !m || !mapInitialized) {
      return;
    }

    const olSource = new VectorSource({ wrapX: false });
    const olVectorLayer = new VectorLayer({
      source: olSource,
      style: CloudOpenlayersMap.GeomStyles.Polygon.regular({
        color: '#007bff',
      }),
    });

    const olDraw = new Draw({
      source: olSource,
      type: 'Circle',
      geometryFunction: createBox(),
      freehand: true,
      style: CloudOpenlayersMap.GeomStyles.Polygon.regular({
        color: '#339BC0',
        fillOpacity: '80',
      }),
    });
    m.addInteraction(olDraw);
    m.addLayer(olVectorLayer);

    olDraw.on('drawend', (e: DrawEvent) => {
      setEnableSelectionDraw(false);
      const olFeature: Feature<Polygon> = e.feature as Feature<Polygon>;
      const polygon = olFeature.getGeometry();
      if (!polygon) {
        return;
      }
      const multiPolygon = new MultiPolygon([polygon]);
      onPolygonSelected?.(multiPolygon);
    });

    return () => {
      olSource.dispose();
      olDraw.dispose();
      olVectorLayer.dispose();
      m.removeInteraction(olDraw);
      m.removeLayer(olVectorLayer);
    };
  }, [enableSelectionDraw]);

  if (!mapInitialized) {
    return null;
  }

  if (enableSelectionDraw) {
    return (
      <>
        <div
          className={`${
            isMobile ? 'fixed' : 'absolute'
          } left-1/2 -translate-x-1/2 px-6 py-3
          bottom-28 bg-black bg-opacity-60 text-white rounded-lg`}
        >
          Click and drag to draw a box
        </div>
        <div
          className={`${
            isMobile ? 'fixed' : 'absolute'
          } bottom-14 left-1/2 -translate-x-1/2
z-1 bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1`}
        >
          <CloudButton.Icon
            id={`${id}-cancel-extent-draw-button`}
            leadingIcon="close"
            leadingIconColor="text-status-error"
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              setEnableSelectionDraw(false);
            }}
          />
        </div>
      </>
    );
  }

  if (selectedPolygon === undefined) {
    return (
      <div
        className={`${
          isMobile ? 'fixed' : 'absolute'
        } bottom-14 left-1/2 -translate-x-1/2
  z-1 bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1`}
      >
        <CloudButton.Icon
          id={`${id}-search-by-area-button`}
          buttonRef={buttonRef}
          leadingIcon="search"
          label="Search by Area"
        />
        <ContextMenu
          childRef={buttonRef}
          show={showContextMenu}
          setShow={setShowContextMenu}
        >
          <ContextMenu.Content>
            <ContextMenu.Item
              label="Current View"
              icon="expand"
              onClick={() => {
                const m = map.current;
                if (!m) {
                  return;
                }

                const extent = m.getView().calculateExtent();
                const polygon = fromExtent(extent);
                const multiPolygon = new MultiPolygon([polygon]);
                onPolygonSelected?.(multiPolygon);
                setShowContextMenu(false);
              }}
            />
            <ContextMenu.Item
              label="Draw a Box"
              icon="square-dashed"
              onClick={() => {
                setEnableSelectionDraw(true);
                setShowContextMenu(false);
              }}
            />
            <ContextMenu.Item
              label="Choose from Library"
              icon="draw-polygon"
              onClick={() => {
                setShowBoundarySelectModal(true);
                setShowContextMenu(false);
              }}
            />
          </ContextMenu.Content>
        </ContextMenu>
        <Modal
          open={showBoundarySelectModal}
          setOpen={(value) => {
            if (loadingBoundarySearch) {
              return;
            }
            setShowBoundarySelectModal(value);
          }}
        >
          <Modal.Title title="Choose a Boundary" />
          <Modal.Content
            className={`px-6 pb-6 pt-2 ${isMobile ? 'w-full' : 'w-96'}`}
          >
            {(organizationsQuery.data?.length ?? 0) > 1 && (
              <div className="mb-4">
                <CloudInput.Select.Single
                  id="organization-select"
                  loading={organizationsQuery.isLoading}
                  options={organizationsQuery.data ?? []}
                  disabled={loadingBoundarySearch}
                  highlighted={filter?.customerId !== undefined}
                  label="Organization"
                  value={selectedOrganization}
                  setValue={setSelectedOrganization}
                  optionBuilder={(option) =>
                    option?.customerDisplayName ??
                    option?.customerName ??
                    'Unknown'
                  }
                />
              </div>
            )}

            <CloudInput.Select.Multi.InlineList
              id="choose-boundary-geometries"
              value={selectedLibraryItems}
              setValue={setSelectedLibraryItems}
              options={libraryItems ?? []}
              optionBuilder={(option) => option?.name ?? 'Unknown'}
              optionIconBuilder={() => 'draw-polygon'}
              loading={
                libraryQuery.isFetching ||
                libraryQuery.isFetchingNextPage ||
                filter === undefined
              }
              validation={{
                validationState: formValidation,
                validators: [
                  CloudInput.validators.required(
                    'Boundaries are required',
                    true
                  ),
                ],
              }}
              error={formValidation.errors['choose-boundary-geometries']}
              pagination={{
                loadNextPage: async () => {
                  if (
                    libraryQuery.isFetching ||
                    libraryQuery.isFetchingNextPage ||
                    !libraryQuery.hasNextPage ||
                    filter === undefined
                  ) {
                    return;
                  }

                  libraryQuery.fetchNextPage();
                },
              }}
              overlay={() => {
                if (
                  libraryQuery.isFetching ||
                  libraryQuery.isFetchingNextPage ||
                  libraryQuery.isLoading ||
                  filter === undefined
                ) {
                  return null;
                }

                if ((libraryItems?.length ?? 0) > 0) {
                  return null;
                }

                if ((filter?.name?.trim() ?? '') !== '') {
                  return (
                    <CloudInput.Select.Multi.InlineList.BasicOverlay subtitle="No Matching Boundaries" />
                  );
                }

                if (
                  filter?.customerId !== undefined &&
                  (organizationsQuery.data?.length ?? 0) > 0
                ) {
                  return (
                    <CloudInput.Select.Multi.InlineList.BasicOverlay subtitle="No Matching Boundaries" />
                  );
                }

                return (
                  <CloudInput.Select.Multi.InlineList.BasicOverlay subtitle="No Boundaries" />
                );
              }}
            >
              <div className="p-2">
                <CloudInput.Text.Single
                  id="boundary-name-filter"
                  value={boundaryNameFilter}
                  disabled={loadingBoundarySearch}
                  highlighted={(filter?.name?.trim() ?? '') !== ''}
                  setValue={setBoundaryNameFilter}
                  placeholder="Filter"
                  leadingIcon="search"
                />
              </div>
            </CloudInput.Select.Multi.InlineList>
            <Modal.Actions>
              <CloudButton.Tertiary
                id="cancel-boundary-select"
                label="Cancel"
                disabled={loadingBoundarySearch}
                onClick={() => setShowBoundarySelectModal(false)}
              />
              <CloudButton.Primary
                id="search-by-boundary"
                label="Search"
                loading={loadingBoundarySearch}
                onClick={async () => {
                  if (await formValidation.hasErrors()) {
                    return;
                  }

                  searchBySelectedBoundaries();
                }}
              />
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  if (selectedPolygon !== undefined) {
    return (
      <div
        className={`${
          isMobile ? 'fixed' : 'absolute'
        } bottom-14 left-1/2 -translate-x-1/2
      z-1 bg-white shadow-lg rounded-lg p-1 flex flex-row gap-1`}
      >
        <CloudButton.Icon
          id={`${id}-clear-extent-button`}
          leadingIcon="close"
          label="Clear Area Search"
          onClick={(e) => {
            onPolygonSelected?.(undefined);
            e.stopPropagation();
          }}
        />
      </div>
    );
  }

  return null;
};

const CloudOpenlayersMapOverlays = {
  BaseMapControls: CloudOpenlayersMapBaseMapControls,
  ViewControls: CloudOpenlayersMapViewControls,
  AnnotationTools: CloudOpenlayersAnnotationTools,
  ExtentSelector: CloudOpenlayersExtentSelector,
};

export { CloudOpenlayersMapOverlays };
