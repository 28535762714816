/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { EptPointcloud, EptPointcloudFilter } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getEptPointcloud = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<EptPointcloud[]>(
    { url: `/api/EptPointcloud`, method: 'GET', signal },
    options
  );
};

export const getGetEptPointcloudQueryKey = () => {
  return [`/api/EptPointcloud`] as const;
};

export const getGetEptPointcloudQueryOptions = <
  TData = Awaited<ReturnType<typeof getEptPointcloud>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getEptPointcloud>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEptPointcloudQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEptPointcloud>>
  > = ({ signal }) => getEptPointcloud(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEptPointcloud>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEptPointcloudQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEptPointcloud>>
>;
export type GetEptPointcloudQueryError = ErrorType<void>;

export const useGetEptPointcloud = <
  TData = Awaited<ReturnType<typeof getEptPointcloud>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getEptPointcloud>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEptPointcloudQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postEptPointcloud = (
  eptPointcloud: BodyType<EptPointcloud>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<EptPointcloud>(
    {
      url: `/api/EptPointcloud`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: eptPointcloud,
    },
    options
  );
};

export const getPostEptPointcloudMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postEptPointcloud>>,
    TError,
    { data: BodyType<EptPointcloud> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postEptPointcloud>>,
  TError,
  { data: BodyType<EptPointcloud> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postEptPointcloud>>,
    { data: BodyType<EptPointcloud> }
  > = (props) => {
    const { data } = props ?? {};

    return postEptPointcloud(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostEptPointcloudMutationResult = NonNullable<
  Awaited<ReturnType<typeof postEptPointcloud>>
>;
export type PostEptPointcloudMutationBody = BodyType<EptPointcloud>;
export type PostEptPointcloudMutationError = ErrorType<void>;

export const usePostEptPointcloud = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postEptPointcloud>>,
    TError,
    { data: BodyType<EptPointcloud> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postEptPointcloud>>,
  TError,
  { data: BodyType<EptPointcloud> },
  TContext
> => {
  const mutationOptions = getPostEptPointcloudMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedEptPointclouds = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<EptPointcloud[]>(
    {
      url: `/api/EptPointcloud/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedEptPointcloudsQueryKey = (
  skip: number,
  take: number
) => {
  return [`/api/EptPointcloud/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedEptPointcloudsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedEptPointclouds>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedEptPointclouds>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedEptPointcloudsQueryKey(skip, take);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedEptPointclouds>>
  > = ({ signal }) =>
    getPagedEptPointclouds(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedEptPointclouds>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedEptPointcloudsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedEptPointclouds>>
>;
export type GetPagedEptPointcloudsQueryError = ErrorType<void>;

export const useGetPagedEptPointclouds = <
  TData = Awaited<ReturnType<typeof getPagedEptPointclouds>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedEptPointclouds>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedEptPointcloudsQueryOptions(
    skip,
    take,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedEptPointclouds = (
  skip: number,
  take: number,
  eptPointcloudFilter: BodyType<EptPointcloudFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<EptPointcloud[]>(
    {
      url: `/api/EptPointcloud/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: eptPointcloudFilter,
    },
    options
  );
};

export const getGetFilteredPagedEptPointcloudsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>,
    TError,
    { skip: number; take: number; data: BodyType<EptPointcloudFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>,
  TError,
  { skip: number; take: number; data: BodyType<EptPointcloudFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>,
    { skip: number; take: number; data: BodyType<EptPointcloudFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedEptPointclouds(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedEptPointcloudsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>
>;
export type GetFilteredPagedEptPointcloudsMutationBody =
  BodyType<EptPointcloudFilter>;
export type GetFilteredPagedEptPointcloudsMutationError = ErrorType<void>;

export const useGetFilteredPagedEptPointclouds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>,
    TError,
    { skip: number; take: number; data: BodyType<EptPointcloudFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedEptPointclouds>>,
  TError,
  { skip: number; take: number; data: BodyType<EptPointcloudFilter> },
  TContext
> => {
  const mutationOptions =
    getGetFilteredPagedEptPointcloudsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getEptPointcloudById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<EptPointcloud>(
    {
      url: `/api/EptPointcloud/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetEptPointcloudByIdQueryKey = (id: number) => {
  return [`/api/EptPointcloud/${id}`] as const;
};

export const getGetEptPointcloudByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEptPointcloudById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEptPointcloudById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEptPointcloudByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEptPointcloudById>>
  > = ({ signal }) => getEptPointcloudById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEptPointcloudById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEptPointcloudByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEptPointcloudById>>
>;
export type GetEptPointcloudByIdQueryError = ErrorType<void>;

export const useGetEptPointcloudById = <
  TData = Awaited<ReturnType<typeof getEptPointcloudById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEptPointcloudById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEptPointcloudByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteEptPointcloud = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<EptPointcloud>(
    {
      url: `/api/EptPointcloud/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteEptPointcloudMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEptPointcloud>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEptPointcloud>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEptPointcloud>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteEptPointcloud(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEptPointcloudMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEptPointcloud>>
>;

export type DeleteEptPointcloudMutationError = ErrorType<void>;

export const useDeleteEptPointcloud = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEptPointcloud>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEptPointcloud>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteEptPointcloudMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getEptJsonById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Blob>(
    {
      url: `/api/EptPointcloud/${encodeURIComponent(String(id))}/ept.json`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getGetEptJsonByIdQueryKey = (id: number) => {
  return [`/api/EptPointcloud/${id}/ept.json`] as const;
};

export const getGetEptJsonByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEptJsonById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEptJsonById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEptJsonByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEptJsonById>>> = ({
    signal,
  }) => getEptJsonById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEptJsonById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEptJsonByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEptJsonById>>
>;
export type GetEptJsonByIdQueryError = ErrorType<void>;

export const useGetEptJsonById = <
  TData = Awaited<ReturnType<typeof getEptJsonById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEptJsonById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEptJsonByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEptBuildJsonById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<string>(
    {
      url: `/api/EptPointcloud/${encodeURIComponent(
        String(id)
      )}/ept-build.json`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetEptBuildJsonByIdQueryKey = (id: number) => {
  return [`/api/EptPointcloud/${id}/ept-build.json`] as const;
};

export const getGetEptBuildJsonByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEptBuildJsonById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEptBuildJsonById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEptBuildJsonByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEptBuildJsonById>>
  > = ({ signal }) => getEptBuildJsonById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEptBuildJsonById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEptBuildJsonByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEptBuildJsonById>>
>;
export type GetEptBuildJsonByIdQueryError = ErrorType<void>;

export const useGetEptBuildJsonById = <
  TData = Awaited<ReturnType<typeof getEptBuildJsonById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getEptBuildJsonById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEptBuildJsonByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEptDataById = (
  id: number,
  folderName: string,
  fileName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<string>(
    {
      url: `/api/EptPointcloud/${encodeURIComponent(
        String(id)
      )}/${encodeURIComponent(String(folderName))}/${encodeURIComponent(
        String(fileName)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetEptDataByIdQueryKey = (
  id: number,
  folderName: string,
  fileName: string
) => {
  return [`/api/EptPointcloud/${id}/${folderName}/${fileName}`] as const;
};

export const getGetEptDataByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEptDataById>>,
  TError = ErrorType<void>
>(
  id: number,
  folderName: string,
  fileName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEptDataById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetEptDataByIdQueryKey(id, folderName, fileName);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEptDataById>>> = ({
    signal,
  }) => getEptDataById(id, folderName, fileName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(id && folderName && fileName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEptDataById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEptDataByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEptDataById>>
>;
export type GetEptDataByIdQueryError = ErrorType<void>;

export const useGetEptDataById = <
  TData = Awaited<ReturnType<typeof getEptDataById>>,
  TError = ErrorType<void>
>(
  id: number,
  folderName: string,
  fileName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEptDataById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEptDataByIdQueryOptions(
    id,
    folderName,
    fileName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
