/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  Block,
  BlockCollectionCount,
  BlockFilter,
  BlockRanking,
  BlockSummaryPage,
  GetBlockCrops200,
  MapPin,
} from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getBlock = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Block[]>(
    { url: `/api/Blocks`, method: 'GET', signal },
    options
  );
};

export const getGetBlockQueryKey = () => {
  return [`/api/Blocks`] as const;
};

export const getGetBlockQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlock>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getBlock>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBlockQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBlock>>> = ({
    signal,
  }) => getBlock(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlock>>
>;
export type GetBlockQueryError = ErrorType<void>;

export const useGetBlock = <
  TData = Awaited<ReturnType<typeof getBlock>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getBlock>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postBlock = (
  block: BodyType<Block>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Block>(
    {
      url: `/api/Blocks`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: block,
    },
    options
  );
};

export const getPostBlockMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postBlock>>,
    TError,
    { data: BodyType<Block> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postBlock>>,
  TError,
  { data: BodyType<Block> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postBlock>>,
    { data: BodyType<Block> }
  > = (props) => {
    const { data } = props ?? {};

    return postBlock(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostBlockMutationResult = NonNullable<
  Awaited<ReturnType<typeof postBlock>>
>;
export type PostBlockMutationBody = BodyType<Block>;
export type PostBlockMutationError = ErrorType<void>;

export const usePostBlock = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postBlock>>,
    TError,
    { data: BodyType<Block> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postBlock>>,
  TError,
  { data: BodyType<Block> },
  TContext
> => {
  const mutationOptions = getPostBlockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPagedBlocks = (
  skip: number,
  take: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Block[]>(
    {
      url: `/api/Blocks/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedBlocksQueryKey = (skip: number, take: number) => {
  return [`/api/Blocks/skip/${skip}/take/${take}`] as const;
};

export const getGetPagedBlocksQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedBlocks>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPagedBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPagedBlocksQueryKey(skip, take);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPagedBlocks>>> = ({
    signal,
  }) => getPagedBlocks(skip, take, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(skip && take),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedBlocksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedBlocks>>
>;
export type GetPagedBlocksQueryError = ErrorType<void>;

export const useGetPagedBlocks = <
  TData = Awaited<ReturnType<typeof getPagedBlocks>>,
  TError = ErrorType<void>
>(
  skip: number,
  take: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPagedBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedBlocksQueryOptions(skip, take, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFilteredPagedBlocks = (
  skip: number,
  take: number,
  blockFilter: BodyType<BlockFilter>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Block[]>(
    {
      url: `/api/Blocks/skip/${encodeURIComponent(
        String(skip)
      )}/take/${encodeURIComponent(String(take))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: blockFilter,
    },
    options
  );
};

export const getGetFilteredPagedBlocksMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedBlocks>>,
    TError,
    { skip: number; take: number; data: BodyType<BlockFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFilteredPagedBlocks>>,
  TError,
  { skip: number; take: number; data: BodyType<BlockFilter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFilteredPagedBlocks>>,
    { skip: number; take: number; data: BodyType<BlockFilter> }
  > = (props) => {
    const { skip, take, data } = props ?? {};

    return getFilteredPagedBlocks(skip, take, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetFilteredPagedBlocksMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFilteredPagedBlocks>>
>;
export type GetFilteredPagedBlocksMutationBody = BodyType<BlockFilter>;
export type GetFilteredPagedBlocksMutationError = ErrorType<void>;

export const useGetFilteredPagedBlocks = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFilteredPagedBlocks>>,
    TError,
    { skip: number; take: number; data: BodyType<BlockFilter> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getFilteredPagedBlocks>>,
  TError,
  { skip: number; take: number; data: BodyType<BlockFilter> },
  TContext
> => {
  const mutationOptions = getGetFilteredPagedBlocksMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getBlockById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Block>(
    {
      url: `/api/Blocks/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBlockByIdQueryKey = (id: number) => {
  return [`/api/Blocks/${id}`] as const;
};

export const getGetBlockByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlockById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBlockById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBlockByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBlockById>>> = ({
    signal,
  }) => getBlockById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlockById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlockById>>
>;
export type GetBlockByIdQueryError = ErrorType<void>;

export const useGetBlockById = <
  TData = Awaited<ReturnType<typeof getBlockById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getBlockById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putBlock = (
  id: number,
  block: BodyType<Block>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Block>(
    {
      url: `/api/Blocks/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: block,
    },
    options
  );
};

export const getPutBlockMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putBlock>>,
    TError,
    { id: number; data: BodyType<Block> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putBlock>>,
  TError,
  { id: number; data: BodyType<Block> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putBlock>>,
    { id: number; data: BodyType<Block> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putBlock(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutBlockMutationResult = NonNullable<
  Awaited<ReturnType<typeof putBlock>>
>;
export type PutBlockMutationBody = BodyType<Block>;
export type PutBlockMutationError = ErrorType<void>;

export const usePutBlock = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putBlock>>,
    TError,
    { id: number; data: BodyType<Block> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putBlock>>,
  TError,
  { id: number; data: BodyType<Block> },
  TContext
> => {
  const mutationOptions = getPutBlockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteBlock = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Block>(
    { url: `/api/Blocks/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteBlockMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBlock>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteBlock>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteBlock>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteBlock(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteBlockMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteBlock>>
>;

export type DeleteBlockMutationError = ErrorType<void>;

export const useDeleteBlock = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBlock>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteBlock>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteBlockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getBlockCrops = (
  getBlockCropsBody: BodyType<number[]>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<GetBlockCrops200>(
    {
      url: `/api/Blocks/GetBlockCrops`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getBlockCropsBody,
    },
    options
  );
};

export const getGetBlockCropsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getBlockCrops>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getBlockCrops>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getBlockCrops>>,
    { data: BodyType<number[]> }
  > = (props) => {
    const { data } = props ?? {};

    return getBlockCrops(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetBlockCropsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getBlockCrops>>
>;
export type GetBlockCropsMutationBody = BodyType<number[]>;
export type GetBlockCropsMutationError = ErrorType<void>;

export const useGetBlockCrops = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getBlockCrops>>,
    TError,
    { data: BodyType<number[]> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof getBlockCrops>>,
  TError,
  { data: BodyType<number[]> },
  TContext
> => {
  const mutationOptions = getGetBlockCropsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getBlockCollectionCountPerService = (
  farmId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BlockCollectionCount[]>(
    {
      url: `/api/Blocks/GetBlockCollectionCountPerService/${encodeURIComponent(
        String(farmId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBlockCollectionCountPerServiceQueryKey = (
  farmId: number
) => {
  return [`/api/Blocks/GetBlockCollectionCountPerService/${farmId}`] as const;
};

export const getGetBlockCollectionCountPerServiceQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlockCollectionCountPerService>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockCollectionCountPerService>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBlockCollectionCountPerServiceQueryKey(farmId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBlockCollectionCountPerService>>
  > = ({ signal }) =>
    getBlockCollectionCountPerService(farmId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!farmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlockCollectionCountPerService>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockCollectionCountPerServiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlockCollectionCountPerService>>
>;
export type GetBlockCollectionCountPerServiceQueryError = ErrorType<void>;

export const useGetBlockCollectionCountPerService = <
  TData = Awaited<ReturnType<typeof getBlockCollectionCountPerService>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockCollectionCountPerService>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockCollectionCountPerServiceQueryOptions(
    farmId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFarmBlocks = (
  farmId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Block[]>(
    {
      url: `/api/Blocks/GetFarmBlocks/${encodeURIComponent(String(farmId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetFarmBlocksQueryKey = (farmId: number) => {
  return [`/api/Blocks/GetFarmBlocks/${farmId}`] as const;
};

export const getGetFarmBlocksQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmBlocks>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFarmBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFarmBlocksQueryKey(farmId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFarmBlocks>>> = ({
    signal,
  }) => getFarmBlocks(farmId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!farmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarmBlocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmBlocksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFarmBlocks>>
>;
export type GetFarmBlocksQueryError = ErrorType<void>;

export const useGetFarmBlocks = <
  TData = Awaited<ReturnType<typeof getFarmBlocks>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFarmBlocks>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmBlocksQueryOptions(farmId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFarmBlocksWithYield = (
  farmId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Block[]>(
    {
      url: `/api/Blocks/GetFarmBlocksWithYield/${encodeURIComponent(
        String(farmId)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetFarmBlocksWithYieldQueryKey = (farmId: number) => {
  return [`/api/Blocks/GetFarmBlocksWithYield/${farmId}`] as const;
};

export const getGetFarmBlocksWithYieldQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmBlocksWithYield>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFarmBlocksWithYield>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFarmBlocksWithYieldQueryKey(farmId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFarmBlocksWithYield>>
  > = ({ signal }) => getFarmBlocksWithYield(farmId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!farmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarmBlocksWithYield>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmBlocksWithYieldQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFarmBlocksWithYield>>
>;
export type GetFarmBlocksWithYieldQueryError = ErrorType<void>;

export const useGetFarmBlocksWithYield = <
  TData = Awaited<ReturnType<typeof getFarmBlocksWithYield>>,
  TError = ErrorType<void>
>(
  farmId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFarmBlocksWithYield>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmBlocksWithYieldQueryOptions(farmId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFarmBlocksSummary = (
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BlockSummaryPage[]>(
    {
      url: `/api/Blocks/GetPagedFarmBlocksSummary/${encodeURIComponent(
        String(farmId)
      )}/${encodeURIComponent(String(pageNumber))}/${encodeURIComponent(
        String(pageSize)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetFarmBlocksSummaryQueryKey = (
  farmId: number,
  pageNumber: number,
  pageSize: number
) => {
  return [
    `/api/Blocks/GetPagedFarmBlocksSummary/${farmId}/${pageNumber}/${pageSize}`,
  ] as const;
};

export const getGetFarmBlocksSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmBlocksSummary>>,
  TError = ErrorType<void>
>(
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFarmBlocksSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFarmBlocksSummaryQueryKey(farmId, pageNumber, pageSize);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFarmBlocksSummary>>
  > = ({ signal }) =>
    getFarmBlocksSummary(farmId, pageNumber, pageSize, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(farmId && pageNumber && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarmBlocksSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmBlocksSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFarmBlocksSummary>>
>;
export type GetFarmBlocksSummaryQueryError = ErrorType<void>;

export const useGetFarmBlocksSummary = <
  TData = Awaited<ReturnType<typeof getFarmBlocksSummary>>,
  TError = ErrorType<void>
>(
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFarmBlocksSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmBlocksSummaryQueryOptions(
    farmId,
    pageNumber,
    pageSize,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedBlocksSummaryWithCounts = (
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BlockSummaryPage>(
    {
      url: `/api/Blocks/GetPagedFarmBlocksSummaryWithCounts/${encodeURIComponent(
        String(farmId)
      )}/${encodeURIComponent(String(pageNumber))}/${encodeURIComponent(
        String(pageSize)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedBlocksSummaryWithCountsQueryKey = (
  farmId: number,
  pageNumber: number,
  pageSize: number
) => {
  return [
    `/api/Blocks/GetPagedFarmBlocksSummaryWithCounts/${farmId}/${pageNumber}/${pageSize}`,
  ] as const;
};

export const getGetPagedBlocksSummaryWithCountsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>,
  TError = ErrorType<void>
>(
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedBlocksSummaryWithCountsQueryKey(farmId, pageNumber, pageSize);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>
  > = ({ signal }) =>
    getPagedBlocksSummaryWithCounts(
      farmId,
      pageNumber,
      pageSize,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(farmId && pageNumber && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedBlocksSummaryWithCountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>
>;
export type GetPagedBlocksSummaryWithCountsQueryError = ErrorType<void>;

export const useGetPagedBlocksSummaryWithCounts = <
  TData = Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>,
  TError = ErrorType<void>
>(
  farmId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedBlocksSummaryWithCounts>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedBlocksSummaryWithCountsQueryOptions(
    farmId,
    pageNumber,
    pageSize,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPagedFarmBlocksSummaryByMorphology = (
  farmId: number,
  blockId: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BlockSummaryPage>(
    {
      url: `/api/Blocks/GetPagedFarmBlocksSummary/${encodeURIComponent(
        String(farmId)
      )}/Block/${encodeURIComponent(String(blockId))}/${encodeURIComponent(
        String(pageSize)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPagedFarmBlocksSummaryByMorphologyQueryKey = (
  farmId: number,
  blockId: number,
  pageSize: number
) => {
  return [
    `/api/Blocks/GetPagedFarmBlocksSummary/${farmId}/Block/${blockId}/${pageSize}`,
  ] as const;
};

export const getGetPagedFarmBlocksSummaryByMorphologyQueryOptions = <
  TData = Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>,
  TError = ErrorType<void>
>(
  farmId: number,
  blockId: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPagedFarmBlocksSummaryByMorphologyQueryKey(farmId, blockId, pageSize);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>
  > = ({ signal }) =>
    getPagedFarmBlocksSummaryByMorphology(
      farmId,
      blockId,
      pageSize,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(farmId && blockId && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPagedFarmBlocksSummaryByMorphologyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>
>;
export type GetPagedFarmBlocksSummaryByMorphologyQueryError = ErrorType<void>;

export const useGetPagedFarmBlocksSummaryByMorphology = <
  TData = Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>,
  TError = ErrorType<void>
>(
  farmId: number,
  blockId: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPagedFarmBlocksSummaryByMorphology>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPagedFarmBlocksSummaryByMorphologyQueryOptions(
    farmId,
    blockId,
    pageSize,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBlockGeoJson = (
  collectionUuid: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<string>(
    {
      url: `/api/Blocks/GetBlockGeoJson/${encodeURIComponent(
        String(collectionUuid)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBlockGeoJsonQueryKey = (collectionUuid: string) => {
  return [`/api/Blocks/GetBlockGeoJson/${collectionUuid}`] as const;
};

export const getGetBlockGeoJsonQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlockGeoJson>>,
  TError = ErrorType<void>
>(
  collectionUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockGeoJson>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBlockGeoJsonQueryKey(collectionUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBlockGeoJson>>> = ({
    signal,
  }) => getBlockGeoJson(collectionUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!collectionUuid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlockGeoJson>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockGeoJsonQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlockGeoJson>>
>;
export type GetBlockGeoJsonQueryError = ErrorType<void>;

export const useGetBlockGeoJson = <
  TData = Awaited<ReturnType<typeof getBlockGeoJson>>,
  TError = ErrorType<void>
>(
  collectionUuid: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockGeoJson>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockGeoJsonQueryOptions(collectionUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBlockFarmPinInfo = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<MapPin>(
    {
      url: `/api/Blocks/GetBlockFarmPinInfo/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBlockFarmPinInfoQueryKey = (id: number) => {
  return [`/api/Blocks/GetBlockFarmPinInfo/${id}`] as const;
};

export const getGetBlockFarmPinInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlockFarmPinInfo>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockFarmPinInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBlockFarmPinInfoQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBlockFarmPinInfo>>
  > = ({ signal }) => getBlockFarmPinInfo(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlockFarmPinInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockFarmPinInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlockFarmPinInfo>>
>;
export type GetBlockFarmPinInfoQueryError = ErrorType<void>;

export const useGetBlockFarmPinInfo = <
  TData = Awaited<ReturnType<typeof getBlockFarmPinInfo>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockFarmPinInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockFarmPinInfoQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBlockRankings = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<BlockRanking>(
    {
      url: `/api/Blocks/GetBlockRankings/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetBlockRankingsQueryKey = (id: number) => {
  return [`/api/Blocks/GetBlockRankings/${id}`] as const;
};

export const getGetBlockRankingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlockRankings>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockRankings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBlockRankingsQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBlockRankings>>
  > = ({ signal }) => getBlockRankings(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBlockRankings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBlockRankingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlockRankings>>
>;
export type GetBlockRankingsQueryError = ErrorType<void>;

export const useGetBlockRankings = <
  TData = Awaited<ReturnType<typeof getBlockRankings>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBlockRankings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlockRankingsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
