import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import {
  Color,
  Euler,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { APIClient } from '@agerpoint/api';
import {
  Annotation2dPoints,
  Annotation3dLines,
  Annotation3dPoints,
  Annotation3dPolygons,
  ColorsThreeD,
  EventBusNames,
  GeomType,
  HexColor,
  IAnnotations2dGeometry,
  IAnnotations3dGeometry,
  ICustomLine,
  ICustomMesh,
  Line,
  MixpanelNames,
  Point,
  Polygon,
  SpecialCaptureObject,
} from '@agerpoint/types';
import { AnnotationGroupName, eventBus } from '@agerpoint/utilities';

import { AnnotationDrawing } from './annotations-drawing/annotations-drawing';

export class AnnotationsController extends AnnotationDrawing {
  camera: PerspectiveCamera;
  controls: OrbitControls | undefined;

  protected animationLoopRequestId: number | undefined;
  protected targetEl: HTMLElement;

  private previousPosition = new Vector3();
  private previousRotation = new Euler();

  private mouse: Vector2 = new Vector2();

  private _boundCameraChanged: () => void;
  private _selectedObjectStream = new BehaviorSubject<
    ICustomMesh | ICustomLine | undefined | null
  >(undefined);
  private clickedMousePosition = new Vector3();
  private sendEvent = (eventName: MixpanelNames, eventBody?: object) => {};

  constructor(
    camera: PerspectiveCamera,
    scene: Scene,
    controls: OrbitControls,
    targetEl: HTMLElement,
    mouseRef: React.MutableRefObject<Vector3 | undefined>,
    viewOptions: {
      isPotree: boolean;
      isMobile: boolean;
      isReadOnly: boolean;
    },
    reactMethods: {
      sendEvent: (eventName: MixpanelNames, eventBody?: object) => void;
    }
  ) {
    super(
      camera,
      scene,
      targetEl,
      mouseRef,
      viewOptions.isPotree,
      viewOptions.isReadOnly
    );
    this.camera = camera;
    this.camera.far = 1000;
    this.camera.near = 0.0001;
    this.controls = controls;
    this.targetEl = targetEl;
    this.sendEvent = reactMethods.sendEvent;
    if (!viewOptions.isReadOnly) {
      targetEl.addEventListener('mousemove', this.mouseMove);
      targetEl.addEventListener('mouseout', () => {
        document.body.style.cursor = 'default';
      });
      targetEl.addEventListener('mouseenter', () => {
        document.body.style.cursor = 'grab';
      });
    }

    if (!viewOptions.isMobile && !viewOptions.isReadOnly) {
      this.annotation2d?.on('mousedown', this.object2dMouseDown);
      this.annotation2d?.on('mouseup', this.object2dMouseUp);
      this.annotation3d?.on('label-click', this.object3dLabelClick);
    }

    if (!viewOptions.isReadOnly) {
      this.mouseReticle = this.createWhiteSphereReticle();
      this.scene.add(this.mouseReticle);
    }
    this._boundCameraChanged = this.cameraChanged.bind(this);
    if (viewOptions.isPotree) {
      this.targetEl.addEventListener('wheel', this._boundCameraChanged);
    } else {
      this.controls.addEventListener('change', this._boundCameraChanged);
    }

    const boundSelectedObjectCb = this.selectedObjectCb.bind(this);
    eventBus.on(EventBusNames.SidebarAnnotationClicked, boundSelectedObjectCb);
    this.startAnimationLoop();
  }

  get creating2dPoint() {
    return this._creating2dPoint;
  }
  get creating3dLine() {
    return this._creating3dLine;
  }
  get creating3dMultiPoint() {
    return this._creating3dMultiPoint;
  }

  get editing2dPoint() {
    return !!this.point2dBeingEdited_uniqueId;
  }
  get editing3dLine() {
    return !!this.line3dBeingEdited_uniqueId;
  }
  get editing2dMultiPoint() {
    return !!this.multiPoint2dBeingEdited_uniqueId;
  }

  get activeTool() {
    if (this._creating2dPoint) return GeomType.Point;
    if (this._creating3dLine) return GeomType.LineString;
    if (this._creating3dPolygon) return GeomType.Polygon;
    if (this._creating3dMultiPoint) return GeomType.MultiPoint;
    if (this._editing3dLine) return GeomType.LineString;
    if (this._editing2dPoint) return GeomType.Point;
    if (this.point2dBeingEdited_uniqueId) return GeomType.Point;
    if (this.multiPoint2dBeingEdited_uniqueId) return GeomType.MultiPoint;
    if (this.line3dBeingEdited_uniqueId) return GeomType.LineString;
    return '';
  }

  clickedPosition(pos: Vector3) {
    this.clickedMousePosition = pos;
  }

  selectedObjectSubject = () => {
    return this._selectedObjectStream.pipe(
      distinctUntilChanged((prev, curr) => curr?.id === prev?.id)
    );
  };

  object2dMouseDown = (id: string) => {
    const point = this.annotation2d?.get2dPointById(id);
    const multiPointChild = this.annotation2d?.get2dMultiPointByChildId(id);
    const multiPointParent = this.annotation2d?.get2dMultiPointById(id);
    if (!point && !multiPointChild && !multiPointParent) {
      this.cancelEditing2dPoint();

      return;
    }

    this.annotation2d?.annoMgr.captureObjectWasClickedIn3D(id);

    if (point) {
      this.multiPoint2dBeingEdited_uniqueId = '';
      if (this._creating2dPoint) {
        this.annotation2d?.unHighlightAll();
        this.finishCreating2dPoint(false);
        return;
      }

      if (id === this.point2dBeingEdited_uniqueId) {
        this.startMoving2dPoint();
      } else {
        this.annotation2d?.highlight2dPointById(id);
        this.startEditing2dPoint(id);
      }
    }

    if (multiPointChild) {
      this.point2dBeingEdited_uniqueId = '';
      // we can reuse point2d edit methods for multiPoint children
      const childPoint = multiPointChild.getChildById(id);
      if (!childPoint) {
        this.annotation2d?.unHighlightAll();
        this.cancelEditing2dPoint();
        return;
      }

      if (this._creating2dMultiPoint) {
        this.annotation2d?.unHighlightAll();
        this.add2dMultiPointToPending(id);
        return;
      }
      if (id === this.multiPoint2dBeingEdited_uniqueId) {
        this.startMoving2dMultiPoint();
      } else {
        this.annotation2d?.highlight2dMultiPointById(multiPointChild?.uniqueId);
        this.startEditing2dMultiPoint(childPoint?.uniqueId);
      }
    }
    if (multiPointParent) {
      this.point2dBeingEdited_uniqueId = '';
      this.annotation2d?.highlight2dMultiPointById(multiPointParent?.uniqueId);
      this.startEditing2dMultiPoint(multiPointParent?.uniqueId);
    }
  };

  object2dMouseUp = (id: string) => {
    const point = this.annotation2d?.get2dPointById(id);
    const multiPoint = this.annotation2d?.get2dMultiPointByChildId(id);

    if (!point && !multiPoint) {
      this.cancelEditing2dPoint();
      return;
    }
    if (this._editing2dPoint && point) {
      this.annotation2d?.unHighlightAll();
      this.finishEditing2dPoint();
    }
    if (this._editing2dMultiPoint && multiPoint) {
      this.annotation2d?.unHighlightAll();
      this.finishEditing2dMultiPoint();
    }
  };

  object2dReset() {
    this.annotation2d?.unHighlightAll2d();
    this.point2dBeingEdited_uniqueId = '';
    this.multiPoint2dBeingEdited_uniqueId = '';
  }

  object3dLabelClick = (id: string, type: Annotation3dLines) => {
    if (this.isReadOnly) return;
    if (type === Annotation3dLines.AnnotationLine) {
      const line = this.annotation3d?.get3dLineById(id);
      if (!line) return;
      this.object3dMouseDown(line.lineObject);
    }
  };

  object3dMouseDown(object: ICustomLine | ICustomMesh) {
    this.object2dReset();
    if (this.isReadOnly) return;
    if (!object) return;
    this.annotation3d?.annoMgr.captureObjectWasClickedIn3D(object.uniqueId);
    if (this._creating3dLine && this.pendingLine) {
      this.addNewPointToPendingLine(this.clickedMousePosition);
      return;
    }
    if (
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating2dPoint ||
      this._creating3dMultiPoint
    )
      return;

    if (
      object.customType === Annotation3dPoints.AnnotationLineMidpointVertex &&
      this._editing3dLine
    ) {
      this.convertMidpointToVertex(object);
      this.updateCursor(this.mouse);
      return;
    }

    if (object.customType === Annotation3dLines.AnnotationLine) {
      this.annotation3d?.highlight3dLineById(object.uniqueId);

      if (!this._editing3dLine) {
        this.startEditing3dLine(object.uniqueId);
      } else {
        this.finishEditing3dLineVertex();
        this.startEditing3dLine(object.uniqueId);
      }
      return;
    }

    if (object.customType === Annotation3dPolygons.AnnotationPolygon) {
      this.annotation3d?.highlight3dPolygonById(object.uniqueId);
      return;
    }
  }

  object3dMouseDragStart(object: ICustomLine | ICustomMesh | undefined | null) {
    if (this.isReadOnly) return;
    if (!object) return;
    if (
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating2dPoint ||
      this._creating3dMultiPoint
    )
      return;
    if (
      object.customType === Annotation3dPoints.AnnotationLineVertex &&
      this._editing3dLine
    ) {
      this.startEditing3dLineVertex(object);
      return;
    }
    if (object.customType === Annotation3dLines.AnnotationLine) {
      this.annotation3d?.highlight3dLineById(object.uniqueId);
      return;
    }
  }

  object3dDoubleClick(object: ICustomLine | ICustomMesh) {
    if (this.isReadOnly) return;
    if (!object) return;
    if (object.customType === Annotation3dPoints.AnnotationLineVertex) {
      this.deleteLineVertex(object);
    }

    if (object.customType === Annotation3dLines.PendingLine) {
      this.addNewPointToPendingLine(this.clickedMousePosition);
      this.finishCreating3dLine();
    }
  }

  mouseUp() {
    if (this.isReadOnly) return;
    if (this._editing3dLine) {
      this.finishEditing3dLineVertex();
    }
    if (this._editing2dPoint) {
      this.finishEditing2dPoint();
    }
  }

  mouseMove = (event: MouseEvent) => {
    if (this.isReadOnly) return;
    this.mouse = new Vector2();
    const rect = this.targetEl.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    this.updateCursor(this.mouse);
  };

  sceneWasClicked() {
    if (this.isReadOnly) return;
    this.annotation3d?.unHighlightAll();
    this.annotation2d?.unHighlightAll();

    if (this._creating2dPoint && this.mousePosition) {
      this._creating2dPoint = false;
      const pnt = this.annotation2d?.get2dPointById(this.new2dPointId || '');
      if (pnt) {
        pnt.updatePosition(this.mousePosition);
        pnt.updateType(Annotation2dPoints.AnnotationPoint2d);
      }
      this.finishCreating2dPoint(false);
      return;
    }

    if (this._creating2dMultiPoint && this.mousePosition) {
      const pnt = new Vector3().copy(this.mousePosition);
      if (pnt) {
        this.pending2dMultiPoint?.push(pnt);
        this.annotation2d?.updatePending2dMultiPointPositions(
          this.new2dMultiPointId || '',
          [...(this.pending2dMultiPoint || [])]
        );
      }
      return;
    }

    if (this._creating3dLine && this.pendingLine) {
      this.addNewPointToPendingLine(this.clickedMousePosition);
      return;
    }

    if (this._editing3dLine) {
      this.finishEditing3dLine();
    }
    if (this.isInEditMode && this.point2dBeingEdited_uniqueId) {
      this.cancelEditing2dPoint();
    }

    this.multiPoint2dBeingEdited_uniqueId = '';
    this.point2dBeingEdited_uniqueId = '';
    this.line3dBeingEdited_uniqueId = '';
    this.noMoreEditing();
  }

  sceneWasDoubleClicked() {
    this.annotation3d?.unHighlightAll();
    this.annotation2d?.unHighlightAll();
    // change pivot point to the point clicked
    if (this.isPotree) {
      return;
    }
    if (!this.mousePosition) {
      return;
    }
    this.camera.lookAt(this.mousePosition);
    this.controls?.target?.copy(this.mousePosition);
    this.controls?.update();
  }

  setMousePosition(pos: Vector3 | undefined) {
    if (!pos) {
      this.mousePosition = undefined;
    } else {
      this.mousePosition = pos;
    }
  }

  seedCaptureObjects(captureObjects: APIClient.CaptureObject[]) {
    this.noMoreEditing();
    this.resetEditingIds();
    this.resetPendingGeometry();
    this.calculateFrustumPlanes();
    if (!captureObjects?.length) {
      return;
    }

    captureObjects.forEach((co) => {
      const obj: SpecialCaptureObject = co as SpecialCaptureObject;
      const id = obj.id?.toString() || '';
      if (!id) return;
      const color = this.getColor(obj) ?? ColorsThreeD.Cyan;
      const description = obj.description || '';
      const name = obj.name || '';

      const partialCoAttrs = {
        name,
        description,
        id,
        color,
      };

      if (obj?.geom2D?.type === GeomType.Point) {
        const position = obj?.geom2D?.coordinates as Point;

        this.annotation2d?.add2dPoint(
          EventBusNames.Annotation2dPointLocation,
          id,
          new Vector3(position[0], position[1], position[2]),
          {
            fill: color,
            name,
            visible: true,
            visibleLabel: true,
            type: Annotation2dPoints.AnnotationPoint2d,
            clickable: !this.isReadOnly,
            editable: false,
          }
        );
      } else if (obj?.geom2D?.type === GeomType.LineString) {
        const positions = obj?.geom2D?.coordinates as Line;
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation3dLines.AnnotationLine,
        };
        this.annotation3d?.add3dLine(
          coAttrs,
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dLines.AnnotationLine
        );
      } else if (obj?.geom2D?.type === GeomType.Polygon) {
        const positions = obj?.geom2D?.coordinates as Polygon;
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation3dPolygons.AnnotationPolygon,
        };
        this.annotation3d?.add3dPolygon(
          coAttrs,
          positions[0].map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          Annotation3dPolygons.AnnotationPolygon
        );
      } else if (obj?.geom2D?.type === GeomType.MultiPoint) {
        const positions = obj?.geom2D?.coordinates as Point[];
        const coAttrs = {
          ...partialCoAttrs,
          type: Annotation2dPoints.AnnotationMultiPoint2d,
        };
        this.annotation2d?.add2dMultiPoint(
          id,
          positions.map((p: number[]) => new Vector3(p[0], p[1], p[2])),
          coAttrs
        );
      }
    });
    this._boundCameraChanged();
  }

  render() {
    const currentPosition = this.camera.position;
    const currentRotation = this.camera.rotation;
    this.setMousePosition(this.mousePositionRef.current);
    if (this._creating2dPoint) {
      this.updatePendingPnt();
    }
    if (this._creating2dMultiPoint) {
      this.updatePending2dMultiPoint();
    }
    if (this._creating3dLine) {
      this.updatePendingLine();
    }
    if (this._creating3dPolygon) {
      this.updatePendingPolygon();
    }
    if (this.isInEditMode && this._editing3dLine) {
      this.updateExistingLine();
    }
    if (this.isInEditMode && this._editing2dPoint) {
      this.updateExisting2dPoint();
    }
    if (this.isInEditMode && this._editing2dMultiPoint) {
      this.update2dMultiPointPosition();
    }
    if (
      !currentPosition.equals(this.previousPosition) ||
      !currentRotation.equals(this.previousRotation)
    ) {
      this.calculateFrustumPlanes();
    }
    this.annotation3d?.render();
    this.annotation2d?.render();

    this.previousPosition.copy(currentPosition);
    this.previousRotation.copy(currentRotation);
  }

  startAnimationLoop = () => {
    this.animationLoopRequestId = requestAnimationFrame(
      this.startAnimationLoop
    );
    this.render();
  };

  destroy() {
    this._selectedObjectStream.complete();
    super.destroy();

    cancelAnimationFrame(this.animationLoopRequestId || 0);
    if (this.mouseReticle && this.scene) {
      this.scene.remove(this.mouseReticle);
    }
    this.targetEl.removeEventListener('mousemove', this.mouseMove);
    this.targetEl.removeEventListener('wheel', this._boundCameraChanged);
    this.controls?.removeEventListener('change', this._boundCameraChanged);
  }

  private cameraChanged() {
    this.annotation3d?.cameraChanged();
  }

  private getColor(obj: SpecialCaptureObject) {
    return obj?.captureObjectCustomAttributes?.find(
      (attr) => attr.attributeName === 'color'
    )?.attributeValue as HexColor;
  }

  private updatePendingPnt() {
    if (this.mousePosition) {
      const pnt = this.annotation2d?.get2dPointById(this.new2dPointId || '');
      if (pnt) {
        pnt.updatePosition(this.mousePosition);
      }
    }
  }

  private updatePending2dMultiPoint() {
    if (this.mousePosition && this._creating2dMultiPoint) {
      const multiPoint = this.annotation2d?.get2dMultiPointById(
        this.new2dMultiPointId || ''
      );
      if (multiPoint) {
        this.annotation2d?.updatePending2dMultiPointPositions(
          this.new2dMultiPointId || '',
          [
            ...(this.pending2dMultiPoint?.slice(0, -1) || []),
            this.mousePosition,
          ]
        );
      }
    }
  }

  private updatePendingLine() {
    if (this.mousePosition && this.pendingLine?.length) {
      this.annotation3d?.update3dLinePosition(this.new3dLineId || '', [
        ...this.pendingLine,
        this.mousePosition,
      ]);
    }
  }

  private updatePendingPolygon() {
    if (this.mousePosition && this.pendingPolygon?.length) {
      this.annotation3d?.update3dPolygonPosition(this.new3dPolygonId || '', [
        ...this.pendingPolygon,
        this.mousePosition,
        this.pendingPolygon[0],
      ]);
    }
  }

  private getIntersected3dObjects(pos: Vector2) {
    if (!this.scene || !this.camera) return;
    const group = this.scene.getObjectByName(AnnotationGroupName);
    if (!group) return;
    const raycaster = new Raycaster();

    raycaster.setFromCamera(pos, this.camera);
    const intersects = raycaster.intersectObjects(group.children, true);

    return intersects.filter((intersect) => intersect.object.visible);
  }

  private mouseOverHighlightLookup = new Map<string, Color>();

  private updateSelectedObject() {}

  private updateCursor(pos: Vector2) {
    if (!this.camera || !this.mouseReticle || !this.mousePosition) {
      if (this.mouseReticle) {
        this.mouseReticle.visible = false;
      }
      document.body.style.cursor = 'grab';
      return;
    }

    const intersects = this.getIntersected3dObjects(pos);
    if (intersects?.length) {
      const intersectedObject = intersects[0].object as
        | ICustomLine
        | ICustomMesh;
      const isMidpoint =
        intersectedObject.customType ===
        Annotation3dPoints.AnnotationLineMidpointVertex;
      const isVertex =
        intersectedObject.customType ===
        Annotation3dPoints.AnnotationLineVertex;

      if (isMidpoint) {
        document.body.style.cursor = 'copy';
      } else {
        document.body.style.cursor = 'pointer';
      }
      if (isMidpoint || isVertex) {
        const currentMaterial = intersectedObject.material as MeshBasicMaterial;

        // Only store the original color if it's not already highlighted
        if (!this.mouseOverHighlightLookup.has(intersectedObject.uuid)) {
          const oldColor = currentMaterial.color.clone(); // Clone to preserve the original color
          this.mouseOverHighlightLookup.set(intersectedObject.uuid, oldColor);
        }

        currentMaterial.color.set(ColorsThreeD.Yellow); // Set the highlight color
        currentMaterial.needsUpdate = true; // Force material update
      }
      this.mouseReticle.visible = false;
      this._selectedObjectStream.next(intersectedObject);
      return;
    } else if (this.mouseOverHighlightLookup.size) {
      // Unhighlight mouseOverHighlightLookup
      this.mouseOverHighlightLookup.forEach((oldColor, key) => {
        const object = this.scene.getObjectByProperty('uuid', key);
        if (object) {
          object.traverse((child) => {
            if ((child as any).isMesh) {
              const meshMaterial = (child as Mesh)
                .material as MeshBasicMaterial;
              if (meshMaterial && this.mouseOverHighlightLookup.has(key)) {
                const storedColor = this.mouseOverHighlightLookup.get(key);
                if (storedColor) {
                  meshMaterial.color.copy(storedColor); // Use .copy() to restore the color properly
                  meshMaterial.needsUpdate = true;
                }
              }
            }
          });
        }
        this.mouseOverHighlightLookup.delete(key); // Only delete after processing
      });
    }
    this._selectedObjectStream.next(undefined);
    if (
      this._creating2dPoint ||
      this._creating3dLine ||
      this._creating3dPolygon ||
      this._creating3dMultiPoint ||
      this._editing2dMultiPoint ||
      this._editing2dPoint ||
      this.point2dBeingEdited_uniqueId ||
      this.multiPoint2dBeingEdited_uniqueId
    ) {
      this.mouseReticle.position.copy(this.mousePosition);
      this.mouseReticle.visible = true;
      document.body.style.cursor = 'none';
      return;
    }

    this.mouseReticle.visible = false;
    document.body.style.cursor = 'grab';
  }

  private addNewPointToPendingLine(pos: Vector3) {
    if (!this.pendingLine || !this.new3dLineId) {
      return;
    }
    const pnt = new Vector3().copy(pos);
    const line = [...this.pendingLine, pnt];
    if (pnt) {
      this.pendingLine = line;
      this.annotation3d?.update3dLinePosition(this.new3dLineId, line);
      this.annotation3d?.highlight3dLineById(this.new3dLineId);
      this.annotation3d?.startEditing3dLineById(this.new3dLineId);
    }
  }

  private getObjectByIdAndType(
    id: string,
    type: Annotation2dPoints | Annotation3dLines
  ): ICustomLine | ICustomMesh | undefined | null {
    if (!type || !id) {
      return;
    }
    let selectObj;

    selectObj = this.scene.getObjectByProperty('uniqueId', id) as
      | ICustomLine
      | ICustomMesh;
    return selectObj;
  }

  private selectedObjectCb({
    detail,
  }: {
    detail:
      | IAnnotations2dGeometry['selected']
      | IAnnotations3dGeometry['selected'];
  }) {
    this.noMoreEditing();
    this.resetEditingIds();
    // if (!detail?.id) {
    //   this._selectedObjectStream.next(undefined);
    //   return;
    // }
    // const object = this.scene.getObjectByProperty('uniqueId', detail.id) as
    //   | ICustomLine
    //   | ICustomMesh;

    // if (!detail.type) {
    //   // do nothing
    // } else if (
    //   Object.values(Annotation2dPoints).includes(
    //     detail.type as Annotation2dPoints
    //   )
    // ) {
    //   this.sceneWasClicked();
    //   this.object2dMouseDown(detail.id);
    // } else if (
    //   Object.values(Annotation3dLines).includes(
    //     detail.type as Annotation3dLines
    //   )
    // ) {
    //   this.sceneWasClicked();
    //   this.object3dMouseDown(object);
    // } else if (
    //   Object.values(Annotation3dPoints).includes(
    //     detail.type as Annotation3dPoints
    //   )
    // ) {
    //   this.sceneWasClicked();
    //   this.object3dMouseDown(object);
    // }
  }
}
