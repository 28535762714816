/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Crop } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCrop = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Crop[]>(
    { url: `/api/Crops`, method: 'GET', signal },
    options
  );
};

export const getGetCropQueryKey = () => {
  return [`/api/Crops`] as const;
};

export const getGetCropQueryOptions = <
  TData = Awaited<ReturnType<typeof getCrop>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCrop>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCropQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCrop>>> = ({
    signal,
  }) => getCrop(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCrop>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCropQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCrop>>
>;
export type GetCropQueryError = ErrorType<void>;

export const useGetCrop = <
  TData = Awaited<ReturnType<typeof getCrop>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCrop>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCropQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCrop = (
  crop: BodyType<Crop>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Crop>(
    {
      url: `/api/Crops`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: crop,
    },
    options
  );
};

export const getPostCropMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCrop>>,
    TError,
    { data: BodyType<Crop> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCrop>>,
  TError,
  { data: BodyType<Crop> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCrop>>,
    { data: BodyType<Crop> }
  > = (props) => {
    const { data } = props ?? {};

    return postCrop(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCropMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCrop>>
>;
export type PostCropMutationBody = BodyType<Crop>;
export type PostCropMutationError = ErrorType<void>;

export const usePostCrop = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCrop>>,
    TError,
    { data: BodyType<Crop> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCrop>>,
  TError,
  { data: BodyType<Crop> },
  TContext
> => {
  const mutationOptions = getPostCropMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCropById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Crop>(
    {
      url: `/api/Crops/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCropByIdQueryKey = (id: number) => {
  return [`/api/Crops/${id}`] as const;
};

export const getGetCropByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCropById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCropById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCropByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCropById>>> = ({
    signal,
  }) => getCropById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCropById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCropByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCropById>>
>;
export type GetCropByIdQueryError = ErrorType<void>;

export const useGetCropById = <
  TData = Awaited<ReturnType<typeof getCropById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCropById>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCropByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putCropById = (
  id: number,
  crop: BodyType<Crop>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Crop>(
    {
      url: `/api/Crops/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: crop,
    },
    options
  );
};

export const getPutCropByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCropById>>,
    TError,
    { id: number; data: BodyType<Crop> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCropById>>,
  TError,
  { id: number; data: BodyType<Crop> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCropById>>,
    { id: number; data: BodyType<Crop> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCropById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCropByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCropById>>
>;
export type PutCropByIdMutationBody = BodyType<Crop>;
export type PutCropByIdMutationError = ErrorType<void>;

export const usePutCropById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCropById>>,
    TError,
    { id: number; data: BodyType<Crop> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCropById>>,
  TError,
  { id: number; data: BodyType<Crop> },
  TContext
> => {
  const mutationOptions = getPutCropByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCrop = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Crop>(
    { url: `/api/Crops/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteCropMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCrop>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCrop>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCrop>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCrop(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCropMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCrop>>
>;

export type DeleteCropMutationError = ErrorType<void>;

export const useDeleteCrop = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCrop>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCrop>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCropMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCropImagePath = (
  collectionId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Crop>(
    {
      url: `/api/Crops/Collections/${encodeURIComponent(String(collectionId))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCropImagePathQueryKey = (collectionId: number) => {
  return [`/api/Crops/Collections/${collectionId}`] as const;
};

export const getGetCropImagePathQueryOptions = <
  TData = Awaited<ReturnType<typeof getCropImagePath>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCropImagePath>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCropImagePathQueryKey(collectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCropImagePath>>
  > = ({ signal }) => getCropImagePath(collectionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!collectionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCropImagePath>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCropImagePathQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCropImagePath>>
>;
export type GetCropImagePathQueryError = ErrorType<void>;

export const useGetCropImagePath = <
  TData = Awaited<ReturnType<typeof getCropImagePath>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCropImagePath>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCropImagePathQueryOptions(collectionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCropWithVarities = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Crop[]>(
    {
      url: `/api/Crops/${encodeURIComponent(String(id))}/Varieties`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCropWithVaritiesQueryKey = (id: number) => {
  return [`/api/Crops/${id}/Varieties`] as const;
};

export const getGetCropWithVaritiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCropWithVarities>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCropWithVarities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCropWithVaritiesQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCropWithVarities>>
  > = ({ signal }) => getCropWithVarities(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCropWithVarities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCropWithVaritiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCropWithVarities>>
>;
export type GetCropWithVaritiesQueryError = ErrorType<void>;

export const useGetCropWithVarities = <
  TData = Awaited<ReturnType<typeof getCropWithVarities>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCropWithVarities>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCropWithVaritiesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
