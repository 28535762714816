import { AuthProvider, useAuth } from 'oidc-react';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Intermediate } from '@agerpoint/feature';
import { environment, useIsViteApp } from '@agerpoint/utilities';

interface AuthSetupProps {
  children: JSX.Element | null;
}

export const AuthSetup = ({ children }: AuthSetupProps) => {
  const REFERRER = 'referrer';

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const isViteApp = useIsViteApp();

  const onBeforeSignIn = useCallback(() => {
    sessionStorage.setItem(REFERRER, pathname + search);
    return '';
  }, [pathname, search]);

  const onSignIn = useCallback(() => {
    const from = sessionStorage.getItem(REFERRER) || '/';
    sessionStorage.removeItem(REFERRER);
    navigate(from);
  }, [navigate]);

  const onSignOut = useCallback(() => {
    sessionStorage.setItem(REFERRER, pathname + search);
  }, [pathname, search]);

  return (
    <AuthProvider
      authority={environment.authentication_server_url}
      clientId="cloud"
      redirectUri={
        environment.app_url + `${isViteApp ? '/app' : ''}/signin-callback`
      }
      postLogoutRedirectUri={environment.app_url + '/signout-callback'}
      scope="openid profile agermetrixapi"
      responseType="code"
      onBeforeSignIn={onBeforeSignIn}
      onSignIn={onSignIn}
      onSignOut={onSignOut}
      automaticSilentRenew={false}
    >
      <AuthGate>{children}</AuthGate>
    </AuthProvider>
  );
};

interface AuthGateProps {
  children: JSX.Element | null;
}

const AuthGate = ({ children }: AuthGateProps) => {
  const { isLoading } = useAuth();
  if (isLoading) {
    return <Intermediate message="Checking for authentication ..." />;
  }

  return children;
};
