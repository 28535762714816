/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';
import type { UploadType } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getUploadType = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<UploadType[]>(
    { url: `/api/UploadTypes`, method: 'GET', signal },
    options
  );
};

export const getGetUploadTypeQueryKey = () => {
  return [`/api/UploadTypes`] as const;
};

export const getGetUploadTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUploadType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUploadTypeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadType>>> = ({
    signal,
  }) => getUploadType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUploadTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadType>>
>;
export type GetUploadTypeQueryError = ErrorType<void>;

export const useGetUploadType = <
  TData = Awaited<ReturnType<typeof getUploadType>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUploadType>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
