/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type {
  Collection,
  CollectionSummary,
  Histogram,
  HistogramCategorized,
  Morphology,
  UploadGeojsonToCollectionBody,
} from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getCollection = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Collection>(
    {
      url: `/api/Collections/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCollectionQueryKey = (id: number) => {
  return [`/api/Collections/${id}`] as const;
};

export const getGetCollectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getCollection>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCollection>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCollectionQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollection>>> = ({
    signal,
  }) => getCollection(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCollection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCollectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCollection>>
>;
export type GetCollectionQueryError = ErrorType<void>;

export const useGetCollection = <
  TData = Awaited<ReturnType<typeof getCollection>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCollection>>, TError, TData>
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCollectionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteCollection = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Collection>(
    {
      url: `/api/Collections/${encodeURIComponent(String(id))}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollection>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCollection>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCollection>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCollection(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCollection>>
>;

export type DeleteCollectionMutationError = ErrorType<void>;

export const useDeleteCollection = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollection>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCollection>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getDeleteCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCollectionSummary = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<CollectionSummary>(
    {
      url: `/api/Collections/${encodeURIComponent(String(id))}/Summary`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetCollectionSummaryQueryKey = (id: number) => {
  return [`/api/Collections/${id}/Summary`] as const;
};

export const getGetCollectionSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getCollectionSummary>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCollectionSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCollectionSummaryQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCollectionSummary>>
  > = ({ signal }) => getCollectionSummary(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCollectionSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCollectionSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCollectionSummary>>
>;
export type GetCollectionSummaryQueryError = ErrorType<void>;

export const useGetCollectionSummary = <
  TData = Awaited<ReturnType<typeof getCollectionSummary>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCollectionSummary>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCollectionSummaryQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMorphologiesByCollectionId = (
  collectionId: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Morphology[]>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMorphologiesByCollectionIdQueryKey = (
  collectionId: number
) => {
  return [`/api/Collections/${collectionId}/Morphologies`] as const;
};

export const getGetMorphologiesByCollectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMorphologiesByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologiesByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMorphologiesByCollectionIdQueryKey(collectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMorphologiesByCollectionId>>
  > = ({ signal }) =>
    getMorphologiesByCollectionId(collectionId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!collectionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMorphologiesByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMorphologiesByCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMorphologiesByCollectionId>>
>;
export type GetMorphologiesByCollectionIdQueryError = ErrorType<void>;

export const useGetMorphologiesByCollectionId = <
  TData = Awaited<ReturnType<typeof getMorphologiesByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologiesByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMorphologiesByCollectionIdQueryOptions(
    collectionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMorphologiesPaginatedByCollectionId = (
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Morphology[]>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies/${encodeURIComponent(
        String(pageNumber)
      )}/${encodeURIComponent(String(pageSize))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMorphologiesPaginatedByCollectionIdQueryKey = (
  collectionId: number,
  pageNumber: number,
  pageSize: number
) => {
  return [
    `/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}`,
  ] as const;
};

export const getGetMorphologiesPaginatedByCollectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMorphologiesPaginatedByCollectionIdQueryKey(
      collectionId,
      pageNumber,
      pageSize
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>
  > = ({ signal }) =>
    getMorphologiesPaginatedByCollectionId(
      collectionId,
      pageNumber,
      pageSize,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(collectionId && pageNumber && pageSize),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMorphologiesPaginatedByCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>
>;
export type GetMorphologiesPaginatedByCollectionIdQueryError = ErrorType<void>;

export const useGetMorphologiesPaginatedByCollectionId = <
  TData = Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologiesPaginatedByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMorphologiesPaginatedByCollectionIdQueryOptions(
    collectionId,
    pageNumber,
    pageSize,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getOrderedMorphologiesPaginatedByCollectionId = (
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Morphology[]>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies/${encodeURIComponent(
        String(pageNumber)
      )}/${encodeURIComponent(String(pageSize))}/OrderBy/${encodeURIComponent(
        String(attributeName)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetOrderedMorphologiesPaginatedByCollectionIdQueryKey = (
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string
) => {
  return [
    `/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}/OrderBy/${attributeName}`,
  ] as const;
};

export const getGetOrderedMorphologiesPaginatedByCollectionIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>
  >,
  TError = ErrorType<void>
>(
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrderedMorphologiesPaginatedByCollectionIdQueryKey(
      collectionId,
      pageNumber,
      pageSize,
      attributeName
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>>
  > = ({ signal }) =>
    getOrderedMorphologiesPaginatedByCollectionId(
      collectionId,
      pageNumber,
      pageSize,
      attributeName,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(collectionId && pageNumber && pageSize && attributeName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderedMorphologiesPaginatedByCollectionIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>>
  >;
export type GetOrderedMorphologiesPaginatedByCollectionIdQueryError =
  ErrorType<void>;

export const useGetOrderedMorphologiesPaginatedByCollectionId = <
  TData = Awaited<
    ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>
  >,
  TError = ErrorType<void>
>(
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof getOrderedMorphologiesPaginatedByCollectionId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetOrderedMorphologiesPaginatedByCollectionIdQueryOptions(
      collectionId,
      pageNumber,
      pageSize,
      attributeName,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getOrderedDescendingMorphologiesPaginatedByCollectionId = (
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Morphology[]>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies/${encodeURIComponent(
        String(pageNumber)
      )}/${encodeURIComponent(
        String(pageSize)
      )}/OrderByDescending/${encodeURIComponent(String(attributeName))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryKey =
  (
    collectionId: number,
    pageNumber: number,
    pageSize: number,
    attributeName: string
  ) => {
    return [
      `/api/Collections/${collectionId}/Morphologies/${pageNumber}/${pageSize}/OrderByDescending/${attributeName}`,
    ] as const;
  };

export const getGetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getOrderedDescendingMorphologiesPaginatedByCollectionId>
    >,
    TError = ErrorType<void>
  >(
    collectionId: number,
    pageNumber: number,
    pageSize: number,
    attributeName: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getOrderedDescendingMorphologiesPaginatedByCollectionId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof AxiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryKey(
        collectionId,
        pageNumber,
        pageSize,
        attributeName
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getOrderedDescendingMorphologiesPaginatedByCollectionId
        >
      >
    > = ({ signal }) =>
      getOrderedDescendingMorphologiesPaginatedByCollectionId(
        collectionId,
        pageNumber,
        pageSize,
        attributeName,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(collectionId && pageNumber && pageSize && attributeName),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getOrderedDescendingMorphologiesPaginatedByCollectionId
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getOrderedDescendingMorphologiesPaginatedByCollectionId>
    >
  >;
export type GetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryError =
  ErrorType<void>;

export const useGetOrderedDescendingMorphologiesPaginatedByCollectionId = <
  TData = Awaited<
    ReturnType<typeof getOrderedDescendingMorphologiesPaginatedByCollectionId>
  >,
  TError = ErrorType<void>
>(
  collectionId: number,
  pageNumber: number,
  pageSize: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getOrderedDescendingMorphologiesPaginatedByCollectionId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetOrderedDescendingMorphologiesPaginatedByCollectionIdQueryOptions(
      collectionId,
      pageNumber,
      pageSize,
      attributeName,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMorphologyHistogramByCollectionId = (
  collectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Histogram>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies/Histogram/${encodeURIComponent(
        String(attributeName)
      )}/${encodeURIComponent(String(binCount))}/${encodeURIComponent(
        String(classificationMethod)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMorphologyHistogramByCollectionIdQueryKey = (
  collectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string
) => {
  return [
    `/api/Collections/${collectionId}/Morphologies/Histogram/${attributeName}/${binCount}/${classificationMethod}`,
  ] as const;
};

export const getGetMorphologyHistogramByCollectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMorphologyHistogramByCollectionIdQueryKey(
      collectionId,
      attributeName,
      binCount,
      classificationMethod
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>
  > = ({ signal }) =>
    getMorphologyHistogramByCollectionId(
      collectionId,
      attributeName,
      binCount,
      classificationMethod,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(
      collectionId &&
      attributeName &&
      binCount &&
      classificationMethod
    ),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMorphologyHistogramByCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>
>;
export type GetMorphologyHistogramByCollectionIdQueryError = ErrorType<void>;

export const useGetMorphologyHistogramByCollectionId = <
  TData = Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>,
  TError = ErrorType<void>
>(
  collectionId: number,
  attributeName: string,
  binCount: number,
  classificationMethod: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologyHistogramByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMorphologyHistogramByCollectionIdQueryOptions(
    collectionId,
    attributeName,
    binCount,
    classificationMethod,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMorphologyCategorizedBinsByCollectionId = (
  collectionId: number,
  attributeName: string,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<HistogramCategorized>(
    {
      url: `/api/Collections/${encodeURIComponent(
        String(collectionId)
      )}/Morphologies/CategorizedHistogram/${encodeURIComponent(
        String(attributeName)
      )}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMorphologyCategorizedBinsByCollectionIdQueryKey = (
  collectionId: number,
  attributeName: string
) => {
  return [
    `/api/Collections/${collectionId}/Morphologies/CategorizedHistogram/${attributeName}`,
  ] as const;
};

export const getGetMorphologyCategorizedBinsByCollectionIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>
  >,
  TError = ErrorType<void>
>(
  collectionId: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMorphologyCategorizedBinsByCollectionIdQueryKey(
      collectionId,
      attributeName
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>>
  > = ({ signal }) =>
    getMorphologyCategorizedBinsByCollectionId(
      collectionId,
      attributeName,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(collectionId && attributeName),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMorphologyCategorizedBinsByCollectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>>
>;
export type GetMorphologyCategorizedBinsByCollectionIdQueryError =
  ErrorType<void>;

export const useGetMorphologyCategorizedBinsByCollectionId = <
  TData = Awaited<
    ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>
  >,
  TError = ErrorType<void>
>(
  collectionId: number,
  attributeName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMorphologyCategorizedBinsByCollectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetMorphologyCategorizedBinsByCollectionIdQueryOptions(
      collectionId,
      attributeName,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const uploadGeojsonToCollection = (
  id: number,
  uploadGeojsonToCollectionBody: BodyType<UploadGeojsonToCollectionBody>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  const formData = new FormData();
  if (uploadGeojsonToCollectionBody.file !== undefined) {
    formData.append('file', uploadGeojsonToCollectionBody.file);
  }

  return AxiosInstance<Collection>(
    {
      url: `/api/Collections/${encodeURIComponent(String(id))}/Upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getUploadGeojsonToCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGeojsonToCollection>>,
    TError,
    { id: number; data: BodyType<UploadGeojsonToCollectionBody> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadGeojsonToCollection>>,
  TError,
  { id: number; data: BodyType<UploadGeojsonToCollectionBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadGeojsonToCollection>>,
    { id: number; data: BodyType<UploadGeojsonToCollectionBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return uploadGeojsonToCollection(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadGeojsonToCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadGeojsonToCollection>>
>;
export type UploadGeojsonToCollectionMutationBody =
  BodyType<UploadGeojsonToCollectionBody>;
export type UploadGeojsonToCollectionMutationError = ErrorType<void>;

export const useUploadGeojsonToCollection = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGeojsonToCollection>>,
    TError,
    { id: number; data: BodyType<UploadGeojsonToCollectionBody> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadGeojsonToCollection>>,
  TError,
  { id: number; data: BodyType<UploadGeojsonToCollectionBody> },
  TContext
> => {
  const mutationOptions = getUploadGeojsonToCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const postCollection = (
  collection: BodyType<Collection>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Collection>(
    {
      url: `/api/Collections`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: collection,
    },
    options
  );
};

export const getPostCollectionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollection>>,
    TError,
    { data: BodyType<Collection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCollection>>,
  TError,
  { data: BodyType<Collection> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCollection>>,
    { data: BodyType<Collection> }
  > = (props) => {
    const { data } = props ?? {};

    return postCollection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCollectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCollection>>
>;
export type PostCollectionMutationBody = BodyType<Collection>;
export type PostCollectionMutationError = ErrorType<void>;

export const usePostCollection = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollection>>,
    TError,
    { data: BodyType<Collection> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCollection>>,
  TError,
  { data: BodyType<Collection> },
  TContext
> => {
  const mutationOptions = getPostCollectionMutationOptions(options);

  return useMutation(mutationOptions);
};
