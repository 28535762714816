/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { CaptureObjectCustomAttribute } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const postCaptureObjectCustomAttribute = (
  captureObjectCustomAttribute: BodyType<CaptureObjectCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectCustomAttribute>(
    {
      url: `/api/CaptureObjectCustomAttributes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectCustomAttribute,
    },
    options
  );
};

export const getPostCaptureObjectCustomAttributeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>,
    TError,
    { data: BodyType<CaptureObjectCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>,
  TError,
  { data: BodyType<CaptureObjectCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>,
    { data: BodyType<CaptureObjectCustomAttribute> }
  > = (props) => {
    const { data } = props ?? {};

    return postCaptureObjectCustomAttribute(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCaptureObjectCustomAttributeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>
>;
export type PostCaptureObjectCustomAttributeMutationBody =
  BodyType<CaptureObjectCustomAttribute>;
export type PostCaptureObjectCustomAttributeMutationError = ErrorType<void>;

export const usePostCaptureObjectCustomAttribute = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>,
    TError,
    { data: BodyType<CaptureObjectCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCaptureObjectCustomAttribute>>,
  TError,
  { data: BodyType<CaptureObjectCustomAttribute> },
  TContext
> => {
  const mutationOptions =
    getPostCaptureObjectCustomAttributeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putCaptureObjectCustomAttributeById = (
  id: number,
  captureObjectCustomAttribute: BodyType<CaptureObjectCustomAttribute>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectCustomAttribute>(
    {
      url: `/api/CaptureObjectCustomAttributes/${encodeURIComponent(
        String(id)
      )}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: captureObjectCustomAttribute,
    },
    options
  );
};

export const getPutCaptureObjectCustomAttributeByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<CaptureObjectCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<CaptureObjectCustomAttribute> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>,
    { id: number; data: BodyType<CaptureObjectCustomAttribute> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putCaptureObjectCustomAttributeById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCaptureObjectCustomAttributeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>
>;
export type PutCaptureObjectCustomAttributeByIdMutationBody =
  BodyType<CaptureObjectCustomAttribute>;
export type PutCaptureObjectCustomAttributeByIdMutationError = ErrorType<void>;

export const usePutCaptureObjectCustomAttributeById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>,
    TError,
    { id: number; data: BodyType<CaptureObjectCustomAttribute> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCaptureObjectCustomAttributeById>>,
  TError,
  { id: number; data: BodyType<CaptureObjectCustomAttribute> },
  TContext
> => {
  const mutationOptions =
    getPutCaptureObjectCustomAttributeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteCaptureObjectCustomAttribute = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<CaptureObjectCustomAttribute>(
    {
      url: `/api/CaptureObjectCustomAttributes/${encodeURIComponent(
        String(id)
      )}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteCaptureObjectCustomAttributeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCaptureObjectCustomAttribute(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaptureObjectCustomAttributeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>
>;

export type DeleteCaptureObjectCustomAttributeMutationError = ErrorType<void>;

export const useDeleteCaptureObjectCustomAttribute = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCaptureObjectCustomAttribute>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions =
    getDeleteCaptureObjectCustomAttributeMutationOptions(options);

  return useMutation(mutationOptions);
};
