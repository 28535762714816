/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Notification } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const sendMessage = (
  notification: BodyType<Notification>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<string>(
    {
      url: `/api/notification`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: notification,
    },
    options
  );
};

export const getSendMessageMutationOptions = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMessage>>,
    TError,
    { data: BodyType<Notification> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendMessage>>,
  TError,
  { data: BodyType<Notification> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMessage>>,
    { data: BodyType<Notification> }
  > = (props) => {
    const { data } = props ?? {};

    return sendMessage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendMessage>>
>;
export type SendMessageMutationBody = BodyType<Notification>;
export type SendMessageMutationError = ErrorType<string | void>;

export const useSendMessage = <
  TError = ErrorType<string | void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMessage>>,
    TError,
    { data: BodyType<Notification> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendMessage>>,
  TError,
  { data: BodyType<Notification> },
  TContext
> => {
  const mutationOptions = getSendMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
