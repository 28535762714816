/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * GeminiAPI
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AxiosInstance } from '../../axios-instance';
import type { BodyType, ErrorType } from '../../axios-instance';
import type { Pipeline } from '.././models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getPipelines = (
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Pipeline[]>(
    { url: `/api/Pipelines`, method: 'GET', signal },
    options
  );
};

export const getGetPipelinesQueryKey = () => {
  return [`/api/Pipelines`] as const;
};

export const getGetPipelinesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPipelines>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPipelines>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPipelinesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPipelines>>> = ({
    signal,
  }) => getPipelines(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPipelines>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPipelinesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPipelines>>
>;
export type GetPipelinesQueryError = ErrorType<void>;

export const useGetPipelines = <
  TData = Awaited<ReturnType<typeof getPipelines>>,
  TError = ErrorType<void>
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getPipelines>>, TError, TData>
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPipelinesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postPipeline = (
  pipeline: BodyType<Pipeline>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Pipeline>(
    {
      url: `/api/Pipelines`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pipeline,
    },
    options
  );
};

export const getPostPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPipeline>>,
    TError,
    { data: BodyType<Pipeline> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPipeline>>,
  TError,
  { data: BodyType<Pipeline> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPipeline>>,
    { data: BodyType<Pipeline> }
  > = (props) => {
    const { data } = props ?? {};

    return postPipeline(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPipeline>>
>;
export type PostPipelineMutationBody = BodyType<Pipeline>;
export type PostPipelineMutationError = ErrorType<void>;

export const usePostPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPipeline>>,
    TError,
    { data: BodyType<Pipeline> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postPipeline>>,
  TError,
  { data: BodyType<Pipeline> },
  TContext
> => {
  const mutationOptions = getPostPipelineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPipelineById = (
  id: number,
  options?: SecondParameter<typeof AxiosInstance>,
  signal?: AbortSignal
) => {
  return AxiosInstance<Pipeline>(
    {
      url: `/api/Pipelines/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetPipelineByIdQueryKey = (id: number) => {
  return [`/api/Pipelines/${id}`] as const;
};

export const getGetPipelineByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPipelineById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPipelineById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPipelineByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPipelineById>>> = ({
    signal,
  }) => getPipelineById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPipelineById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPipelineByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPipelineById>>
>;
export type GetPipelineByIdQueryError = ErrorType<void>;

export const useGetPipelineById = <
  TData = Awaited<ReturnType<typeof getPipelineById>>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPipelineById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof AxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPipelineByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putPipelineById = (
  id: number,
  pipeline: BodyType<Pipeline>,
  options?: SecondParameter<typeof AxiosInstance>
) => {
  return AxiosInstance<Pipeline>(
    {
      url: `/api/Pipelines/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: pipeline,
    },
    options
  );
};

export const getPutPipelineByIdMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPipelineById>>,
    TError,
    { id: number; data: BodyType<Pipeline> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPipelineById>>,
  TError,
  { id: number; data: BodyType<Pipeline> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPipelineById>>,
    { id: number; data: BodyType<Pipeline> }
  > = (props) => {
    const { id, data } = props ?? {};

    return putPipelineById(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPipelineByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPipelineById>>
>;
export type PutPipelineByIdMutationBody = BodyType<Pipeline>;
export type PutPipelineByIdMutationError = ErrorType<void>;

export const usePutPipelineById = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPipelineById>>,
    TError,
    { id: number; data: BodyType<Pipeline> },
    TContext
  >;
  request?: SecondParameter<typeof AxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putPipelineById>>,
  TError,
  { id: number; data: BodyType<Pipeline> },
  TContext
> => {
  const mutationOptions = getPutPipelineByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
